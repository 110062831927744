import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { api } from '../axios/AxiosInstances';
import WoInfotipData from "./WoInfotipData";
import WoWpData from "./WoWpData";
import './dashboard.css';
import Fuse from 'fuse.js';
import Select from "react-select";
import Multiselect from 'react-multiselect-checkbox'; 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const XLSX = require('xlsx');
// import { format } from "date-fns";


function DashboardTable({ refreshData }) {
  const [woList, setWoList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageArray, setPageArray] = useState([1, 2, 3, 4, 5]);
  const [cache, setCache] = useState({});
  const [infotipData, setInfotipData] = useState([]);
  const [infotipListSaved, setInfotipListSaved] = useState([]);
  const [alldata, setalldata] = useState([]);
  const [wpData, setWpData] = useState([]);
  const [shouldFilter, setShouldFilter] = useState(false);
  const [clear, setclear] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([10]);
  const [selectedValue, setSelectedValue] = useState(10); // Default value is 10

  const [infotipWoNumber, setInfotipWoNumber] = useState("");
  const [asp, setAsp] = useState("");
  const [status, setStatus] = useState("");
  const [orderType, setOrderType] = useState("");
  const [workshop, setWorkshop] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [creatorRole, setCreatorRole] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [productName, setProductName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [aspOptions, setAspOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [orderTypeOptions, setOrderTypeOptions] = useState([]);
  const [workshopOptions, setWorkshopOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [creatorRoleOptions, setCreatorRoleOptions] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10); 
  const [loading, setLoading] = useState(true);
  const [loadingWp, setLoadingWp] = useState(true);
  const [uniqueStatusArray, setUniqueStatusArray] = useState([]);
  const [isFiltered, setisFiltered] = useState(false);

  const usertype = localStorage.getItem('usertype')
  const workshopname = localStorage.getItem('workshop')
  const asc = localStorage.getItem('ASC')

  console.log(usertype, workshopname, asc, "typeee")
  const orderTypeMapping = [
    { label: "In Warranty (guarantee)", value: "guarantee" },
    { label: "Out of Warranty (repair)", value: "repair" },
    { label: "DOA (doa)", value: "doa" },
    { label: "Extended Warranty (extguarantee)", value: "extguarantee" },
    { label: "Payable by TPV (goodwill)", value: "goodwill" },
  ];
 

  const handleOrderTypeChange = (event) => {
    setOrderType(event.target.value);
  };

  const getPaginatedElements = (page) => {
    console.log("paginated elements called....")
    if (currentPage === page || page <= 0 || page > totalPages || loading) return;
    setCurrentPage(page)
    if (totalPages <= 5) {
      setPageArray([...Array(totalPages).keys()].map(n => n + 1));
    } else {
      if (page === 1) {
        setPageArray([1, 2, 3, 4, 5]);
      } else if (page < totalPages - 3) {
        setPageArray([page - 1, page, page + 1, page + 2, page + 3]);
      } else {
        setPageArray([totalPages - 5, totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1]);
      }
    }
    setLoading(true)
    console.log(woList , page ,"pagee")
    fetchDetailedInfo(woList, page ,itemsPerPage , isFiltered)
  }

  const fetchDetailedInfo = async (array, page, itemsPerPage , isFiltered) => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, array.length);
    const WoNum = array.slice(startIndex, endIndex);
  
    console.log(startIndex, endIndex, WoNum, "indexx");
  
    // Check if any part of the requested range is not in the cache
    const cachedData = [];
    const missingData = [];
    
    // WoNum.forEach((item, index) => {
    //   const globalIndex = startIndex + index;
    //   console.log(cache[globalIndex],"")
    //   if (cache[globalIndex]){
    //     cachedData.push(cache[globalIndex]);
    //  } else {
    //     missingData.push(item);
    //   }
    // });

  WoNum.forEach((item, index) => {
    const globalIndex = startIndex + index;

    console.log(cache[globalIndex], "cache check");

    if (cache[globalIndex]) {
      if (isFiltered) {
        console.log(cache,cache[globalIndex].OrderName,item, "cache check1");

        // For filtered data, match based on OrderName or any other filter key
        if (cache[globalIndex].OrderName === item) {
          console.log( "cache check2");
          cachedData.push(cache[globalIndex]);
        }
      } else {
        // For unfiltered data, directly add to cachedData
        console.log( "cache check3");
        cachedData.push(cache[globalIndex]);

      }
    } else {
      missingData.push(item);
    }
  });


  
    if (missingData.length === 0) {
      // All required data is in the cache
      console.log("All data is from cache" , WoNum, cachedData ,clear);
      // setalldata(cachedData)
      // setCache(cachedData);
      
      if (clear == true) {
        setInfotipData(alldata);
        setInfotipListSaved(alldata);
      }
      else {
        setInfotipData(cachedData);
        setInfotipListSaved(cachedData);
      }
      setLoading(false);
    } else {
      // Fetch missing data from API
      try {
        console.log(alldata,"check all data before fetch")
        console.log("Fetching missing data from API:", missingData);
        const detailedInfo = await api.post('/getPythonData', { WoNum: missingData });
        const detailedData = Object.values(detailedInfo.data);
  
        // Update the cache with newly fetched data
        const updatedCache = { ...cache };
        detailedData.forEach((item, index) => {
          const globalIndex = startIndex + cachedData.length + index;
          updatedCache[globalIndex] = item;
        });
  
        // setCache(updatedCache);
  
        // Combine cached and fetched data
        const combinedData = [...cachedData, ...detailedData];
        console.log(combinedData,"alldata...")
        setLoading(false);
        if (isFiltered == false) {
          setalldata(combinedData)
          setCache(combinedData);
        }
        setInfotipListSaved(combinedData);
        setInfotipData(combinedData);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data: ", err);
        setLoading(false);
      }
    }
  };
  

  useEffect(() => {

    setOrderTypeOptions(["guarantee", "repair", "doa", "extguarantee", "goodwill"])

     
    const fetchData = async () => {
      try {
        const [countryResult, workshopResult, infotipListResult] = await Promise.all([
          api.get("/countries"),
          api.get("/workshops"),
          api.get("/infotipList")
        ]);

        // setCountryOptions([...new Set(countryResult.data.map(data => data.country_code))]);
        setCountryOptions(['DE', 'FR', 'PL', 'AT', 'IT']);
        setWorkshopOptions([...new Set(workshopResult.data.map(data => data.workshopname))]);
        console.log(infotipListResult.data, itemsPerPage,"dataaa")

        //total wo array
        const listWO = infotipListResult.data.split(',');
        console.log(listWO, "listt?/")
        const firstArray = listWO.map(item => item.split('|')[0]); // First part
        const updatedWOArray = firstArray.slice(1); // Remove the first element
        console.log(updatedWOArray.length, "Updated First Array (First Element Removed)")

        //infotip status array
        const secondArray = listWO.map(item => item.split('|')[1]); // Second part
        setUniqueStatusArray([...new Set(secondArray)]);
        console.log(secondArray, "Unique Elements from Second Array");

        setWoList(updatedWOArray);

        const calculatedTotalPages = Math.ceil(updatedWOArray.length / itemsPerPage);
        setTotalPages(calculatedTotalPages);
        calculatedTotalPages <= 5 && setPageArray([...Array(calculatedTotalPages).keys()].map(n => n + 1));

        await fetchDetailedInfo(updatedWOArray, 1 , updatedWOArray.length , isFiltered);
      } catch (err) {
        console.log("Error getting data: ", err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  //Archive
    const fetchDataA = async () => {
      try {
        const [countryResult, workshopResult, infotipListResult] = await Promise.all([
          api.get("/countries"),
          api.get("/workshops"),
          api.get("/infotipListArchive")
        ]);

        // setCountryOptions([...new Set(countryResult.data.map(data => data.country_code))]);
        setCountryOptions(['DE', 'FR', 'PL', 'AT', 'IT']);
        setWorkshopOptions([...new Set(workshopResult.data.map(data => data.workshopname))]);
        console.log(infotipListResult.data, itemsPerPage,"dataaa")

        //total wo array
        const listWO = infotipListResult.data.split(',');
        console.log(listWO, "listt?/")
        const firstArray = listWO.map(item => item.split('|')[0]); // First part
        const updatedWOArray = firstArray.slice(1); // Remove the first element
        console.log(updatedWOArray.length, "Updated First Array (First Element Removed)")

        //infotip status array
        const secondArray = listWO.map(item => item.split('|')[1]); // Second part
        setUniqueStatusArray([...new Set(secondArray)]);
        console.log(secondArray, "Unique Elements from Second Array");

        setWoList(updatedWOArray);

        const calculatedTotalPages = Math.ceil(updatedWOArray.length / itemsPerPage);
        setTotalPages(calculatedTotalPages);
        calculatedTotalPages <= 5 && setPageArray([...Array(calculatedTotalPages).keys()].map(n => n + 1));

        await fetchDetailedInfo(updatedWOArray, 1 , updatedWOArray.length , isFiltered);
      } catch (err) {
        console.log("Error getting data: ", err);
        setLoading(false);
      }
    };


  useEffect(() => {
    const fetchData = async () => {
      setLoadingWp(true);
      api
        .get("/wps")
        .then((result) => {
          setWpData(result.data);
          setLoadingWp(false);
        })
        .catch((err) => {
          console.log("Error getting data wp: ", err);
        });
    };

    fetchData();
  }, [refreshData]);


  const handleFilterold = useCallback(() => {


    const filters = {
      OrderName: infotipWoNumber,
      // ASP: asp,
      OrderState: status,
      TypeOfOrder: orderType,
      WorkshopName: workshop,
      CountryId: country,
      CreatorRole: creatorRole,
      SerialNumber: serialNumber,
      ProductName: productName,
      Phone: phone,
      Start_Date: startDate,
      End_Date: endDate
    };

    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr.split('.');
      return new Date(`${year}-${month}-${day}`);
    };

    const start = filters.Start_Date ? parseDate(filters.Start_Date) : null;
    const end = filters.End_Date ? parseDate(filters.End_Date) : null;

    let filteredData = [];
    // let filteredData = [...infotipListSaved];

    // Iterate over each object in the data array and apply filters
    console.log("before applying filter...")
    alldata.forEach(item => {
      let passesFilters = true;

      // Check if each property in filters matches the corresponding property in the item
      Object.keys(filters).forEach(key => {
        if (key === 'Start_Date' || key === 'End_Date') {
          console.log("return>>>>>>>>>>>>>>>>>")
          return;
        }

        if (filters[key] && item[key] !== filters[key]) {
          passesFilters = false;
        }
        // Update filter logic for multiple status values
        if (key === 'OrderState' && filters[key].length > 0 && !filters[key].includes(item[key])) {
          passesFilters = false;
        } else if (filters[key] && item[key] !== filters[key]) {
          passesFilters = false;
        }
      });

      // const itemOrderDate = parseDate(item.OrderDate.split(' ')[0]);
      const itemOrderDate = item.OrderDate ? parseDate(item.OrderDate.split(' ')[0]) : null;

      console.log(itemOrderDate,start,end,"date?//")
      // Check if itemOrderDate is within the range
      if (start && itemOrderDate < start) {
        passesFilters = false;
      }
      if (end && itemOrderDate > end) {
        passesFilters = false;
      }

      // If the item passes all filters, add it to the filteredData array
      if (passesFilters) {
        filteredData.push(item);
      }
    });
    setInfotipData(filteredData)
    setCache(filteredData);

    // Extract WO List and Update
  const updatedWOList = filteredData.map((item) => item.OrderName); // Adjust key to match WO name field

    const calculatedTotalPages = Math.ceil(filteredData.length / itemsPerPage);
    setTotalPages(calculatedTotalPages);
    setCurrentPage(1); // Reset to the first page

    if (calculatedTotalPages <= 5) {
      setPageArray([...Array(calculatedTotalPages).keys()].map((n) => n + 1));
    } else {
      setPageArray([1, 2, 3, 4, 5]);
    }

    setWoList(updatedWOList);
    console.log(filteredData,updatedWOList,woList,"after filter")
    setisFiltered(true)
  // show entries changes 
  //  const maxEntries = filteredData.length;
  //  const updatedOptions = [10, 20, 50].filter((value) => value <= maxEntries);
  //  if (updatedOptions.length === 0 && maxEntries > 0) {
  //    updatedOptions.push(maxEntries);
  //  }
  //  setDropdownOptions(updatedOptions);
  }, [status, orderType, workshop, country, creatorRole, infotipWoNumber, serialNumber, productName, phone, startDate, endDate, infotipListSaved]);

  const handleFilter = useCallback(() => {
  setclear(false)
    const filters = {
      OrderName: infotipWoNumber,
      OrderState: status,
      TypeOfOrder: orderType,
      WorkshopName: workshop,
      CountryId: country,
      CreatorRole: creatorRole,
      SerialNumber: serialNumber,
      ProductName: productName,
      Phone: phone,
      Start_Date: startDate, // Format: YYYY-MM-DD
      End_Date: endDate, // Format: YYYY-MM-DD
    };
  
    // Parse date in DD.MM.YYYY format to a Date object
    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr.split(".");
      return new Date(`${year}-${month}-${day}`);
    };
  
    const start = filters.Start_Date ? new Date(filters.Start_Date) : null;
    const end = filters.End_Date ? new Date(filters.End_Date) : null;
  
    let filteredData = [];
  
    // Apply filters
    alldata.forEach((item) => {
      let passesFilters = true;
  
      // Check general filters
      Object.keys(filters).forEach((key) => {
        if (key === "Start_Date" || key === "End_Date") return;
  
        if (filters[key]) {
          if (key === "OrderState" && Array.isArray(filters[key])) {
            if (!filters[key].includes(item[key])) passesFilters = false;
          } else if (item[key] !== filters[key]) {
            passesFilters = false;
          }
        }
      });
  
      // Handle date filtering
      const itemOrderDate = item.OrderDate
        ? parseDate(item.OrderDate.split(" ")[0]) // Extract the date part from DD.MM.YYYY HH:mm:ss
        : null;
  
      if (start && itemOrderDate && itemOrderDate < start) {
        passesFilters = false;
      }
  
      if (end && itemOrderDate && itemOrderDate > end) {
        passesFilters = false;
      }
  
      // Add to filtered data if all conditions pass
      if (passesFilters) {
        filteredData.push(item);
      }
    });
  
    // Update state with filtered data
    setInfotipData(filteredData);
    setCache(filteredData);
  
    // Update pagination
    const updatedWOList = filteredData.map((item) => item.OrderName);
    const calculatedTotalPages = Math.ceil(filteredData.length / itemsPerPage);
    setTotalPages(calculatedTotalPages);
    setCurrentPage(1);
  
    setPageArray(
      calculatedTotalPages <= 5
        ? [...Array(calculatedTotalPages).keys()].map((n) => n + 1)
        : [1, 2, 3, 4, 5]
    );
  
    setWoList(updatedWOList);
    console.log("Filtered data:", filteredData);
    setisFiltered(true);
  }, [
    status,
    orderType,
    workshop,
    country,
    creatorRole,
    infotipWoNumber,
    serialNumber,
    productName,
    phone,
    startDate,
    endDate,
    infotipListSaved,
  ]);
  
  
  

  useEffect(() => {
    if (shouldFilter) {
      handleFilter();
      setShouldFilter(false); // Reset shouldFilter after filtering
    }
  }, [shouldFilter, handleFilter]);




  const handleClear = async () => {
    // Reset all filter input states
    setLoading(true);
    setInfotipWoNumber("");
    setAsp("");
    setStatus("");
    setOrderType("");
    setWorkshop("");
    setCountry("");
    setPhone("");
    setCreatorRole("");
    setSerialNumber("");
    setProductName("");
    setStartDate("");
    setEndDate("");

    setclear(true);
    setShouldFilter(false);
    setisFiltered(false)

    try {
        // Fetch the original data again
        const infotipListResult = await api.get("/infotipList");

        // Process the response correctly
        const listWO = infotipListResult.data.split(",");
        const firstArray = listWO.map(item => item.split("|")[0]); 
        const updatedWOArray = firstArray.slice(1); 

        // Restore the original list
        setWoList(updatedWOArray);

        // Reset pagination settings
        const calculatedTotalPages = Math.ceil(updatedWOArray.length / itemsPerPage);
        setTotalPages(calculatedTotalPages);
        setCurrentPage(1);
        setPageArray(
          calculatedTotalPages <= 5 ? [...Array(calculatedTotalPages).keys()].map(n => n + 1) : [1, 2, 3, 4, 5]
        );

        // Re-fetch detailed info for the full dataset
        fetchDetailedInfo(updatedWOArray, 1, updatedWOArray.length, false);
    } catch (err) {
        console.error("Error resetting data: ", err);
    }
};


 

  const handleExportold = () => {
    const wb = XLSX.utils.book_new();
    console.log(alldata, "asppp")
    const filteredInfotipData = (usertype === 'ASC Leader' || usertype === 'ASC Technician' || usertype === 'TPV Technician')
      ? alldata.filter(item => item.ASP === asc)
      : alldata;

    const excelData = filteredInfotipData.map(({ OrderDate, OrderName, HandlingType, ProductName, SerialNumber, CountryId, WorkshopName, OrderState, CreatorRole, TypeOfOrder }) =>
      ({ OrderDate, OrderName, HandlingType, ProductName, SerialNumber, CountryId, WorkshopName, OrderState, CreatorRole, TypeOfOrder }));

    const ws = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(wb, ws, "Infotip WO");

    // const filteredWpData = wpData.filter(item => item.ASP === asp);
    const filteredWpData = (usertype === 'ASC Leader' || usertype === 'ASC Technician' || usertype === 'TPV Technician')
      ? wpData.filter(item => item.ASP === asc)
      : wpData;

    const excelData2 = filteredWpData.map(({ Order_Date_Time, WP_WO_Number, Handling_Type, Product_Name, Serial_Number, Country, Workshop, ASP, Infotip_Status, Creator_Role, Type_of_Order, RR }) =>
      ({ Order_Date_Time, WP_WO_Number, Handling_Type, Product_Name, Serial_Number, Country, Workshop, ASP, Infotip_Status, Creator_Role, Type_of_Order, RR }));

    const ws2 = XLSX.utils.json_to_sheet(excelData2);
    XLSX.utils.book_append_sheet(wb, ws2, "Woodpecker WO");

    // Save the file
    XLSX.writeFile(wb, 'repair_system_export.xlsx');
  }

  const handleExportold1 = () => {
    const wb = XLSX.utils.book_new();
  
    // Infotip Data export
    const filteredInfotipData = (usertype === 'ASC Leader' || usertype === 'ASC Technician' || usertype === 'TPV Technician')
      ? alldata.filter(item => item.ASP === asc)
      : alldata;
  
    const excelData = filteredInfotipData.map(({ OrderDate, OrderName,OrderState, HandlingType, ProductName, SerialNumber, CountryId, WorkshopName, CreatorRole, TypeOfOrder ,
      ErrorDescription,Wrapup1,Wrapup2, RepeatRepair,TAT,
    }) =>
    ({
      'Infotip WO Date': OrderDate, 'Infotiop WO number': OrderName,
      'Infotip Status': OrderState, 'Creator Role': CreatorRole, 'Type of Order': TypeOfOrder,
      'WP WO Number': '',
      'WP Status': '',
      'Model Name	': ProductName,
      'Serial Number': SerialNumber,
      'Warranty Status': '',
      'Date of Purchase': '',
      'Error Description': ErrorDescription,
      'Wrap Up First Level	': Wrapup1,
      'Wrap Up Second Level	': Wrapup2,
      'Repeat Repair	': RepeatRepair,
      'Service Scenario': '',
      'Submit Group': '',
      'WO Origin	': '',
      'Handling Type': HandlingType,
      'Sales Stock	': '',
      'Dealer Reference': '',
      'Repair Completed Date': '',
      'Customer Country': CountryId,
      'Type of Packaging': '',
      'TAT': TAT,
      'Symptom Code 1': '',
      'Section Code1': '',
      'Defect Code 1	': '',
      'Repair Code 1': '',
      'Cond Code 1	': '',
      'Symptom Code 2': '',
      'Section Code2': '',
      'Defect Code 2	': '',
      'Repair Code 2	': '',
      'Cond Code 2	': '',
      'SPMS Order Number	': '',
      'Spare Part 1 (TPV PN)	': '',
      'Spare Part UID 1	': '',
      'Spare Part 1 ': '',
      'Ordered Quantity': '',
      'Spare Part 2 (TPV PN)': '',
      'Spare Part UID 2	': '',
      'Spare Part 2 Ordered Quantity': '',
      'Spare Part 3 (TPV PN)': '',
      'Spare Part UID 3	': '',
      'Spare Part 3 Ordered Quantity': '',
      'Spare Part 4 (TPV PN)	': '',
      'Spare Part UID 4	': '',
      'Spare Part 4 Ordered Quantity	': '',
      'NSE SPMS Order Number	': '',
      'NSE TV Model Name	': '',
      'NSE TV Grade	': '',
      'NSE TV SN	Authorization ': '',
      'Request Type	': '',
      'Reason Non Standard Exchange	': '',
      'Credit Note Reason	': '',
      'A1/A2 Claim URN	': '',
      'TCA Claim Request Type	': '',
      'TCA Claim URN	': '',
      'TCA Claim Status': ''
    }));
  
    const ws = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(wb, ws, "Infotip WO");
  
    // Woodpecker Data export
    const filteredWpData = (usertype === 'ASC Leader' || usertype === 'ASC Technician' || usertype === 'TPV Technician')
      ? wpData.filter(item => item.ASP === asc)
      : wpData;
  
    const excelData2 = filteredWpData.map(({ Order_Date_Time, WP_WO_Number, Handling_Type, Product_Name, Serial_Number, Country, Workshop, ASP, Infotip_Status, Creator_Role, Type_of_Order, RR }) =>
      ({ 'Order Date Time': Order_Date_Time, 'WP WO Number': WP_WO_Number, 'Handling Type': Handling_Type, 'Product Name': Product_Name, 'Serial Number': Serial_Number, 'Country': Country, 'Workshop': Workshop, 'ASP': ASP, 'Infotip Status': Infotip_Status, 'Creator Role': Creator_Role, 'Type of Order': Type_of_Order, 'RR': RR }));
  
    const ws2 = XLSX.utils.json_to_sheet(excelData2);
    XLSX.utils.book_append_sheet(wb, ws2, "Woodpecker WO");
  
    // Save the file
    XLSX.writeFile(wb, 'repair_system_export.xlsx');
  }
  const extractPlannedDate = (history) => {
    if (!history || !Array.isArray(history)) return null;
  
    for (const entry of history) {
      const match = entry.match(/defecttvarrived date\s*:\s*([\d-]+)/);
      if (match) {
        return match[1]; // Extract the date
      }
    }
  
    return null; // Return null if no date is found
  };
  
  const handleExport = () => {
    const wb = XLSX.utils.book_new();
  
    // Filter infotip data based on user type
    const filteredInfotipData =
      usertype === "ASC Leader" || usertype === "ASC Technician" || usertype === "TPV Technician"
        ? alldata.filter((item) => item.ASP === asc)
        : alldata;
  
    // Map data and apply extractPlannedDate for each entry
    const excelData = filteredInfotipData.map(
      ({
        OrderDate,
        OrderName,
        OrderState,
        HandlingType,
        ProductName,
        SerialNumber,
        SalesDate,
        CountryId,
        WorkshopName,
        CreatorRole,
        TypeOfOrder,
        Wrapup1,
        Wrapup2,
        RepeatRepair,
        TAT,
        TypeOfPackaging,
        history, // Ensure we pass the history array
      }) => {
        const plannedDate = extractPlannedDate(history); // Extract date for each work order
        const formattedDate = plannedDate
          ? new Date(plannedDate).toLocaleDateString("en-GB").replace(/\//g, "-")
          : "";
  
        return {
          "Infotip WO Date": OrderDate,
          "Infotiop WO number": OrderName,
          "Infotip Status": OrderState,
          "Creator Role": CreatorRole,
          "Type of Order": TypeOfOrder,
          "Model Name": ProductName,
          "Serial Number": SerialNumber,
          "Date of Purchase": SalesDate,
          "Wrap Up First Level": Wrapup1,
          "Wrap Up Second Level": Wrapup2,
          "Repeat Repair": RepeatRepair,
          "Service Scenario": "",
          "Handling Type": HandlingType,
          "Handover Date": formattedDate, // Assign correct date per work order
          "Repair Completed Date": "",
          "Customer Country": CountryId,
          "Type of Packaging": TypeOfPackaging,
          "TAT": TAT,
          'Symptom Code 1': '',
          'Section Code1': '',
          'Defect Code 1	': '',
          'Repair Code 1': '',
          'Cond Code 1	': '',
          'Symptom Code 2': '',
          'Section Code2': '',
          'Defect Code 2	': '',
          'Repair Code 2	': '',
          'Cond Code 2	': '',
          'SPMS Order Number	': '',
          'Spare Part 1 (TPV PN)	': '',
          'Spare Part UID 1	': '',
          'Spare Part 1 ': '',
          'Ordered Quantity': '',
          'Spare Part 2 (TPV PN)': '',
          'Spare Part UID 2	': '',
          'Spare Part 2 Ordered Quantity': '',
          'Spare Part 3 (TPV PN)': '',
          'Spare Part UID 3	': '',
          'Spare Part 3 Ordered Quantity': '',
          'Spare Part 4 (TPV PN)	': '',
          'Spare Part UID 4	': '',
          'Spare Part 4 Ordered Quantity	': '',
          'NSE SPMS Order Number	': '',
          'NSE TV Model Name	': '',
          'NSE TV Grade	': '',
          'NSE TV SN	Authorization ': '',
          'Request Type	': '',
          'Reason Non Standard Exchange	': '',
          'Credit Note Reason	': '',
          'A1/A2 Claim URN	': '',
          'TCA Claim Request Type	': '',
          'TCA Claim URN	': '',
          'TCA Claim Status': ''
        };
      }
    );
  
    // Create a new sheet and append it to the workbook
    const ws = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(wb, ws, "Infotip WO");
  
    // Save the Excel file
    XLSX.writeFile(wb, "repair_system_export.xlsx");
  };
  
  
  // const statusOption = Array.from(new Set(infotipData.map((item) => item.OrderState))).map((status) => ({
  //   value: status,
  //   label: status,
  // }));
  // console.log(statusOption,"statusss")
  // const [selectedStatuses, setSelectedStatuses] = useState([]);

  // const handleChange = (selectedOptions) => {
  //   setSelectedStatuses(selectedOptions || []);
  // };

  const [endIndex, setEndIndex] = useState(10);
  const handleEndIndexChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    // Clear cache and reset states
    // setCache({});
    setLoading(true);

    // Reset pagination-related states
    const newTotalPages = Math.ceil(woList.length / selectedItemsPerPage);
    setTotalPages(newTotalPages);
    setCurrentPage(1); // Always start from page 1
    setPageArray(newTotalPages <= 5 ? [...Array(newTotalPages).keys()].map(n => n + 1) : [1, 2, 3, 4, 5]);
  
    // Update the items per page
    setEndIndex(selectedItemsPerPage);
  
    // Fetch data for the first page with the updated items per page
    fetchDetailedInfo(woList, 1, selectedItemsPerPage , isFiltered);
  };
  



  const handleStatusSelect = (selectedStatus) => {
    // selectedStatus is an array of selected options
    const selectedValues = selectedStatus.map(item => item.key); // Get the 'key' of each selected option
    setStatus(selectedValues); // Update status as an array
  };
  
  const handleStatusRemove = (removedStatus) => {
    // If a status is removed, we filter out the corresponding 'key' from the array
    const updatedStatus = status.filter(item => item !== removedStatus.key);
    setStatus(updatedStatus);
  };

  const options = Array.from(new Set(infotipData.map(item => item.OrderState))) // Get unique statuses
  .map(status => ({ key: status, cat: status }));

  
  return (
    <div>
      {/* {console.log(infotipData)} */}
      <div className="dashboard-container">
        {/* <h6>Workorder Dashboard</h6> */}
        <div className="dashboard-table-container">
          <div className="table-top">
            <div className="table-item">
              <label>Infotip WO no.: </label>
              <input type="text" value={infotipWoNumber} onChange={(e) => setInfotipWoNumber(e.target.value)} />
            </div>

            {(usertype === 'TPV Leader' || usertype === 'Manager') && (
              <div className="table-item">
                <label>ASC: </label>
                <select value={asp} onChange={(e) => setAsp(e.target.value)}>
                  <option value="">Select ASC</option>
                  {aspOptions.map((data, i) => (
                    <option key={i} value={data}>{data}</option>
                  ))}
                </select>
              </div>
            )}

            {usertype === 'TPV Technician' && (
              <div className="table-item">
                <label>ASC: </label>
                <select value={asp} onChange={(e) => setAsp(e.target.value)}>
                  <option value="" selected>None</option>
                </select>
              </div>
            )}

            {(usertype === 'ASC Leader' || usertype === 'ASC Technician') && (
              <div className="table-item">
                <label>ASC: </label>
                <select value={asp} onChange={(e) => setAsp(e.target.value)}>
                  <option value={asc} selected>{asc}</option>
                </select>
              </div>
            )}

            {/* take it from infotip list */}
            {/* <div className="table-item">
              <label>Infotip Status: </label>
              <select value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="">Select Status</option>
                {Array.from(new Set(uniqueStatusArray.map(item => item))).map((status, i) => (
                  <option key={i} value={status}>{status}</option>
                ))}
              </select>
            </div> */}

<div className="table-item">
      <label>Infotip Status: </label>
      <select value={status} onChange={(e) => setStatus(e.target.value)}>
        <option value="">Select Status</option>
        {uniqueStatusArray.map((statusValue) => (
          <option key={statusValue} value={statusValue}>
            {statusValue}
          </option>
        ))}
      </select>
    </div>
         
            {/* <div className="table-item">
    <label>Infotip Status: </label>
    <Multiselect
      displayValue="key" // Option value displayed in the dropdown
      options={options} // Pass unique status options
      selectedValues={status ? [{ key: status, cat: status }] : []} // Pass single selected value (not an array)
      onSelect={handleStatusSelect}
      onRemove={handleStatusRemove}
      showCheckbox
      placeholder="Select Status"
    />
  </div> */}



            <div className="table-item large">
              <label>Woodpecker Status: </label>
              <select value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="">Select Status</option>
                {statusOptions.map((data, i) => (
                  <option key={i} value={data}>{data}</option>
                ))}
              </select>
            </div>
           

            {(usertype === 'TPV Leader' || usertype === 'Manager' || usertype === 'ASC Leader' || usertype === 'ASC Technician') && (
              <div className="table-item">
                <label>Workshop: </label>
                <select value={workshop} onChange={(e) => setWorkshop(e.target.value)}>
                  <option value="">Select Workshop</option>
                  {workshopOptions.map((data, i) => (
                    <option key={i} value={data}>{data}</option>
                  ))}
                </select>
              </div>
            )}

            {usertype === 'TPV Technician' && (
              <div className="table-item">
                <label>Workshop: </label>
                <select value={workshop} onChange={(e) => setWorkshop(e.target.value)}>
                  <option value="">Select Workshop</option>
                  <option value={workshopname}>{workshopname}</option>
                </select>
              </div>
            )}

            <div className="table-item">
              <label>Serial Number: </label>
              <input type="text" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} />
            </div>

            <div className="table-item">
              <label>Country: </label>
              <select value={country} onChange={(e) => setCountry(e.target.value)}>
                <option value="">Select Country</option>
                {countryOptions.map((data, i) => (
                  <option key={i} value={data}>{data}</option>
                ))}
              </select>
            </div>

            <div className="table-item large">
              <label>Creator Role: </label>
              <select value={creatorRole} onChange={(e) => setCreatorRole(e.target.value)}>
                <option value="">Select Creator Role</option>
                <option value={'CallCenter'}>Callcenter</option>
                {/* {creatorRoleOptions.map((data, i) => (
                  <option key={i} value={data}>{data}</option>
                ))} */}
              </select>
            </div>

            <div className="table-item">
              <label>Type of Order: </label>
              <select value={orderType} onChange={handleOrderTypeChange}>
                <option value="">Select Order Type</option>
                {orderTypeMapping.map((type, index) => (
                  <option key={index} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="table-item">
              <label>Product Name: </label>
              <input type="text" value={productName} onChange={(e) => setProductName(e.target.value)} />
            </div>

            <div className="table-item">
              <label>Customer Phone: </label>
              <input type="text" className="no-spinners" value={phone} onChange={(e) => setPhone(e.target.value)} />
            </div>

            <div className="table-item large">
              <label>Order Date: From </label>
              {/* <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} /> */}
              <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        dateFormat="dd-MM-yyyy"
        placeholderText="  Select a date"
        className="date-input"
      />
      {/* <span>{formatDate(startDate)}</span> Display formatted date */}

              <label>To </label>
              {/* <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} /> */}
              <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        dateFormat="dd-MM-yyyy"
        placeholderText="  Select a date"
        className="date-input"
      />
            </div>
          </div>

          <div className="table-bottom">
          {/* <div>
              <span>Show</span>
              <select className="select-entries" value={endIndex} onChange={handleEndIndexChange}>
                {infotipData.map((_, index) => (
                  <option key={index + 1} value={index + 1}>{index + 1}</option>
                ))}
              </select>
              <span>entries</span>
            </div> */}
            <div>
              <span>Show</span>
              {/* <select className="select-entries" value={endIndex} onChange={handleEndIndexChange}>
                {[10, 20, 50].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select> */}
              <select
  className="select-entries"
  value={itemsPerPage}
  onChange={handleEndIndexChange}
>
  {dropdownOptions.map((value) => (
    <option key={value} value={value}>
      {value}
    </option>
  ))}
</select>

              <span>entries</span>
            </div>

            <div className="table-bottom-right">
              <div direction="row" spacing={4} align="center">
                <button className="dashboard-button filled-button" onClick={handleFilter}>Search</button>
                <button className="dashboard-button filled-button" onClick={fetchDataA}>Search in Archive</button>
                <button className="dashboard-button clear-button" onClick={handleClear}>Clear</button>
                <button className="dashboard-button filled-button" onClick={handleExport}>Export</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <WoInfotipData infotipData={infotipData} endIndex={endIndex} loading={loading} currentPage={currentPage} itemsPerPage={itemsPerPage} />

      {totalPages > 0 &&
        <div style={{ display: "grid" }}>
          <div className="pagination-buttons">
            <button type="button" onClick={() => getPaginatedElements(1)}>First</button>
            <button type="button" onClick={() => getPaginatedElements(currentPage - 1)}>Previous</button>
            {woList.length > 0 && pageArray.map((x, i) => (
              <button className={currentPage === x ? 'current-page-button' : ""} key={i} type="button" onClick={() => getPaginatedElements(x)}>{x}</button>
            ))}
            {totalPages > 6 && <span>...</span>}
            {totalPages > 5 && <button className={currentPage === totalPages ? 'current-page-button' : ""} type="button" onClick={() => getPaginatedElements(totalPages)}>{totalPages}</button>}
            <button type="button" onClick={() => getPaginatedElements(currentPage + 1)}>Next</button>
            <button type="button" onClick={() => getPaginatedElements(totalPages)}>Last</button>
          </div>
        </div>}

      {/* <WoWpData wpData={wpData} loading={loadingWp} /> */}
    </div >
  );
}

export default DashboardTable;
