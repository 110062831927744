
import React, { useImperativeHandle, forwardRef, useRef, useState, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";


const RMA = forwardRef((props, ref) => {
  const [serviceRepaired, setServiceRepaired] = useState(false);
  const [serviceTakenAway, setServiceTakenAway] = useState(false);

  const { infotipData } = props;
  console.log(infotipData, "in RMA fromm??")
  const formRef = useRef(null);
  const formRefEnglish = useRef(null);
  const formRefPolish = useRef(null);

  // const [serviceRepaired, setServiceRepaired] = useState(infotipData?.serviceRepaired ?? false);
  // const [serviceTakenAway, setServiceTakenAway] = useState(infotipData?.serviceTakenAway ?? false);
  useEffect(() => {
    if (infotipData) {
      setServiceRepaired(infotipData.serviceRepaired || false);
      setServiceTakenAway(infotipData.serviceTakenAway || false);
    }
  }, [infotipData]); // Runs whenever infotip updates

  useEffect(() => {
    if (infotipData) {
      handleDownload(); // Auto-trigger download when infotip is set
    }
  }, [infotipData]); // Runs when infotip updates


  const handleDownloadold = () => {
    if (formRef.current && infotipData) {
      html2canvas(formRef.current, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 190;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
        pdf.save("RMA_Form.pdf");
      });
    }
  };
  const handleDownload = () => {
    if (formRefEnglish.current && formRefPolish.current && infotipData) {
      const pdf = new jsPDF("p", "mm", "a4");
  
      // Capture English Page
      html2canvas(formRefEnglish.current, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 190;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
        pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
  
        // Capture Polish Page
        html2canvas(formRefPolish.current, { scale: 2 }).then((canvas2) => {
          const imgData2 = canvas2.toDataURL("image/png");
          pdf.addPage();
          pdf.addImage(imgData2, "PNG", 10, 10, imgWidth, (canvas2.height * imgWidth) / canvas2.width);
  
          pdf.save("RMA_Form.pdf");
        });
      });
    }
  };
  

  useImperativeHandle(ref, () => ({
    downloadForm: handleDownload,
  }));

  const handleCheckboxChange = (checkboxName) => {
    if (checkboxName === "repaired") {
      setServiceRepaired(!serviceRepaired);
    } else if (checkboxName === "takenAway") {
      setServiceTakenAway(!serviceTakenAway);
    }
  };


  return (
    <div style={styles.page}>
      {/* English Page */}
      <div ref={formRefEnglish} style={styles.container}>
      <div style={styles.header}>
          {/*  Important: Replace 'your_logo.png' with the actual path to your logo */}
          <img src="your_logo.png" alt="TPV Logo" style={styles.logo} onError={(e) => e.target.src = 'placeholder_logo.png'} /> {/* Placeholder for missing logo */}
          <h2>TV Repair Service Completion Form</h2>
        </div>

        <div style={styles.infoRow}>
          <p><strong>RMA Number:</strong> {infotipData?.rmaNumber || "N/A"}</p>
          <p><strong>Date:</strong>{new Date().toISOString().split("T")[0]}</p>
        </div>

        <div style={styles.section}>
          <div style={styles.sectionTitle}>TV Detail</div>
          <table style={styles.tvDetailsTable}>
            <tbody>
              {Array.isArray(infotipData?.tvDetails) ? (
                infotipData.tvDetails.map((detail, index) => (
                  <tr key={index}>
                    <td>{detail.label}</td>
                    <td>{detail.value}</td>
                  </tr>
                ))
              ) : (
                <>
                  <tr><td>TV Brand</td><td>{infotipData?.tvBrand || "N/A"}</td></tr>
                  <tr><td>Model Name</td><td>{infotipData?.modelName || "N/A"}</td></tr>
                  <tr><td>Serial Number</td><td>{infotipData?.serialNumber || "N/A"}</td></tr>
                  <tr><td>Warranty Status</td><td>{infotipData?.warrantyStatus || "N/A"}</td></tr>
                  <tr><td>Customer Country</td><td>{infotipData?.customerCountry || "N/A"}</td></tr>
                </>
              )}
            </tbody>
          </table>
        </div>

        <div style={styles.section}>
          <div style={styles.sectionTitle}>Work Order Description</div>
          <table style={styles.table}>
            <tbody>
              <tr><td>Handling Type</td><td>{infotipData?.handlingType || "N/A"}</td></tr>
              <tr>
                <td>Service Solution</td>
                <td>
                  <div style={styles.checkboxList}>
                    <label>
                      <input type="checkbox" onChange={() => handleCheckboxChange("repaired")} /> TV is repaired
                    </label>
                    <label>
                      <input type="checkbox" checked={serviceTakenAway} onChange={() => handleCheckboxChange("takenAway")} /> TV is taken away by serviceman for further repair
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style={styles.section}>
          <div style={styles.sectionTitle}>Service Solution Detail</div>
          <table style={styles.table}>
            <tbody>
                {/* Adding empty rows for spacing */}
                <tr><td style={{ height: "20px" }}></td></tr>
              <tr><td style={{ height: "20px" }}></td></tr>
              <tr><td style={{ height: "20px" }}></td></tr>
              <tr><td style={{ height: "20px" }}></td></tr>
              <tr><td style={{ height: "20px" }}></td></tr>
              <tr><td style={{ height: "20px" }}></td></tr>
              {/* <tr><td>Handling Type</td><td>{infotipData?.handlingType || "N/A"}</td></tr>
              <tr>
                <td>Service Solution</td>
                <td>
                  <div style={styles.checkboxList}>
                    <label>
                      <input type="checkbox" checked={serviceRepaired} onChange={() => handleCheckboxChange("repaired")} /> TV is repaired
                    </label>
                    <label>
                      <input type="checkbox" checked={serviceTakenAway} onChange={() => handleCheckboxChange("takenAway")} /> TV is taken away by serviceman for further repair
                    </label>
                  </div>
                </td>
              </tr> */}

            </tbody>
          </table>
        </div>

       <p>Replaced key components are warranted for two years from the date of delivery of the receiver after repair.</p>
        {/* <p style={styles.terms}>For general terms and conditions of the manufacturer warranty, please refer to Philips website <a href="https://www.philips.pl/c-w/wsparcie-konsumenta/gwarancja/warranty-bl.html" target="_blank" rel="noopener noreferrer">https://www.philips.pl/c-w/wsparcie-konsumenta/gwarancja/warranty-bl.html</a>.</p> */}

        <div style={styles.footer}>
          <div style={styles.signatureRow}> {/* Wrap signatures in a row */}
            <div style={styles.signatureBlock}>
              <p>Date</p>
              <p>Signature (Workshop)</p>
            </div>
            <div style={styles.signatureBlock}>
              <p>Date</p>
              <p>Signature (Customer)</p>
            </div>
          </div> {/* End signature row */}

          <div style={styles.companyInfo}> {/* Company info below */}
            {/* <p>Authorized Servicer of TP Vision for Philips TV</p> */}
            <p><b>TPV Displays Polska Sp. z o.o.</b></p>
            <p>ul. Złotego Smoka 9</p>
            <p>66-400 Gorzów Wielkopolski, Polska</p>
            <p>NIP: 599-300-52-28</p>
            <p>Service contact: tel. 697 064 771; e-mail: europe.repair@tpv-tech.com</p>
            <p>Mon-Fri  08:00-16:00</p>
          </div>
          <div>
            <p>In connection with the service, Your personal data will be processed in accordance with the clause found at  https://tpvpolska.pl/rodo/ - Klauzula RODO Naprawa gwarancyjna</p>
          </div>
        </div>
      </div>
  
      {/* Page Break for Print */}
      <div style={{ pageBreakAfter: "always" }}></div>
  
      {/* Polish Page */}
      <div ref={formRefPolish} style={styles.container}>
      <div style={styles.header}>
          {/*  Important: Replace 'your_logo.png' with the actual path to your logo */}
          <img src="your_logo.png" alt="TPV Logo" style={styles.logo} onError={(e) => e.target.src = 'placeholder_logo.png'} /> {/* Placeholder for missing logo */}
          <h2>Potwierdzenie Obsługi Serwisowej</h2>
        </div>

        <div style={styles.infoRow}>
          <p><strong>Numer zgłoszenia:</strong> {infotipData?.rmaNumber || "N/A"}</p>
          <p><strong>Data:</strong> {new Date().toISOString().split("T")[0]}</p>
        </div>

        <div style={styles.section}>
          <div style={styles.sectionTitle}>Dane Urządzenia</div>
          <table style={styles.tvDetailsTable}>
            <tbody>
              {Array.isArray(infotipData?.tvDetails) ? (
                infotipData.tvDetails.map((detail, index) => (
                  <tr key={index}>
                    <td>{detail.label}</td>
                    <td>{detail.value}</td>
                  </tr>
                ))
              ) : (
                <>
                  <tr><td>Producent TV</td><td>{infotipData?.tvBrand || "N/A"}</td></tr>
                  <tr><td>Model</td><td>{infotipData?.modelName || "N/A"}</td></tr>
                  <tr><td>Numer seryjny</td><td>{infotipData?.serialNumber || "N/A"}</td></tr>
                  <tr><td>Status gwarancji</td><td>{infotipData?.warrantyStatus || "N/A"}</td></tr>
                  {/* <tr><td>Kraj obsługi</td><td>{infotipData?.purchaseDate || "N/A"}</td></tr> */}
                  <tr><td>Kraj obsługi</td><td>{infotipData?.customerCountry || "N/A"}</td></tr>
                </>
          
              )}
            </tbody>
          </table>
        </div>

        <div style={styles.section}>
          <div style={styles.sectionTitle}>Szczegóły Zlecenia</div>
          <table style={styles.table}>
            <tbody>
              {/* <tr><td>Rodzaj obsługi</td><td>{infotipData?.handlingType || "N/A"}</td></tr> */}
              <tr><td>Rodzaj obsługi</td><td>{infotipData?.handlingType === "HOMESERVICE" ? "Naprawa domowa" : infotipData?.handlingType || "N/A"}</td></tr>
              <tr>
                <td>Rozwiazanie</td>
                <td>
                  <div style={styles.checkboxList}>
                    <label>
                      <input type="checkbox"  onChange={() => handleCheckboxChange("repaired")} /> TV został naprawiony 
                    </label>
                    <label>
                      <input type="checkbox" checked={serviceTakenAway} onChange={() => handleCheckboxChange("takenAway")} /> TV zabrany przez technika do naprawy warsztatowej
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style={styles.section}>
          <div style={styles.sectionTitle}>Szczegóły Naprawy</div>
          <table style={styles.table}>
            <tbody>
              {/* Adding empty rows for spacing */}
              <tr><td style={{ height: "20px" }}></td></tr>
              <tr><td style={{ height: "20px" }}></td></tr>
              <tr><td style={{ height: "20px" }}></td></tr>
              <tr><td style={{ height: "20px" }}></td></tr>
              <tr><td style={{ height: "20px" }}></td></tr>
              <tr><td style={{ height: "20px" }}></td></tr>
            </tbody>
          </table>
        </div>


        <p>Na wymienione kluczowe komponenty obowiązuje gwarancja dwa lata liczona od daty doręczenia odbiornika po naprawie.</p>

        <div style={styles.footer}>
          <div style={styles.signatureRow}> {/* Wrap signatures in a row */}
            <div style={styles.signatureBlock}>
              <p>Data</p>
              <p>Podpis (Usługodawca)</p>
            </div>
            <div style={styles.signatureBlock}>
              <p>Data</p>
              <p>Podpis (Klient)</p>
            </div>
          </div> {/* End signature row */}

          <div style={styles.companyInfo}> {/* Company info below */}
            {/* <p>Authorized Servicer of TP Vision for Philips TV</p> */}
            <p><b>TPV Displays Polska Sp. z o.o.</b></p>
            <p>ul. Złotego Smoka 9</p>
            <p>66-400 Gorzów Wielkopolski, Polska</p>
            <p>NIP: 599-300-52-28	</p>
            <p>Kontakt do serwisu: tel. 697 064 771; e-mail: europe.repair@tpv-tech.com</p>	
            <p>Pn-Pt  08:00-16:00	</p>
          </div>
          <div><p>W związku z obsługą serwisową Państwa dane osobowe będą przetwarzane zgodnie z klauzulą znajdującą się na stronie https://tpvpolska.pl/rodo/ - Klauzula RODO Naprawa gwarancyjna</p></div>
        </div>
      </div>
    </div>
  );
  
});

const styles = {
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: '100vh',
    backgroundColor: '#f4f4f4',
  },
  container: {
    padding: "20px",
    background: "#fff",
    color: "#000",
    fontFamily: "Arial, sans-serif",
    border: "1px solid #ddd",
    borderRadius: "5px",
    maxWidth: "800px",
    width: '100%',
    boxSizing: 'border-box',
    margin: "20px 0",
  },
  header: {
    textAlign: "center",
    marginBottom: "15px",
    fontSize: "18px",
    textTransform: "uppercase",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    maxWidth: '200px',
    marginBottom: '10px',
    display: 'block',
    margin: '0 auto',
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  section: {
    marginBottom: '15px',
    borderBottom: '1px solid #ddd',
    paddingBottom: '10px',
  },
  sectionTitle: {
    fontWeight: 'bold',
    color: '#ffffff',
    marginBottom: '5px',
    backgroundColor: '#337ab7', // Light gray background
    padding: '5px 10px',      // Add padding
    borderRadius: '3px',       // Rounded corners
  },
  tvDetailsTable: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px",
    border: '1px solid #ddd' // Add table border
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px",
  },
  td: {
    padding: "8px",
    textAlign: "left",
    border: '1px solid #ddd' // Add cell border
  },
  noBorder: {
    border: 'none',  // Remove all borders
    padding: '8px',  // Maintain padding for spacing
  },
  checkboxList: {
    display: 'flex',
    flexDirection: 'column',
  },
  terms: {
    marginTop: '20px',
    textAlign: 'center',
    fontSize: '14px',
  },
  footer: {
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',  // Stack elements vertically
    alignItems: 'flex-start', // Align to the left
    borderTop: '1px solid #ddd',
    paddingTop: '10px',
  },
  signatureRow: { // New style for the signature blocks row
    display: 'flex',
    gap: "250px",
    // justifyContent: 'space-between', // Space between signature blocks
    width: '100%', // Take full width to distribute space correctly
    marginBottom: '10px' // Space between signature blocks and company info
  },
  signatureBlock: {
    // No need for margin-bottom here
  },
  companyInfo: {
    marginTop: '30px', // Add some spacing
  },
};


export default RMA;