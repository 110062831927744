import React, { useState ,useRef ,useEffect} from "react";
import DatePicker from "react-datepicker";
import ConfirmationModal from "./ConfirmationModal";
import axios from "axios";
import { api } from '../axios/AxiosInstances';
// import "react-datepicker/dist/react-datepicker.css";
import RMA from "./RMA_Form";
import { blue } from "@mui/material/colors";

function HomeVisit({ detailedData }) {
  console.log(detailedData.history,"check planned date available or not??")
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDateTimeInputVisible, setIsDateTimeInputVisible] = useState(false);
  const [plannedDateTime, setPlannedDateTime] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpenEmailSMS , setIsOpenEmailSMS] = useState(false);
  const currentDate = new Date();
  const [email, setEmail] = useState(detailedData.Email)
  const [mobile, setMobile] = useState(detailedData.Mobil)
  const [informByEmail, setInformByEmail] = useState(false);
  const [informByMobile, setInformByMobile] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [newModelName, setNewModelName] = useState("");
  const [toggleNewModelName, setToggleNewModelName] = useState(false);
  const [updateType, setUpdateType] = useState("")
  const [updatedValue, setUpdatedValue] = useState("")
  const [orderIssueNr, setOrderIssueNr] = useState(null);
  const [detailedData1, setDetailedData] = useState(null);
  const [confirmDisabled, setConfirmDisabled] = useState(true); 
  const rmaRef = useRef(null);

  const extractPlannedDate = () => {
    const entryWithDate = detailedData.history.find(entry => 
      entry.includes("homevisitplanned date")
    );
  
    if (entryWithDate) {
      const match = entryWithDate.match(/homevisitplanned date\s*:\s*([\d-]+)/);
      return match ? match[1] : null; // Extract the date part
    }
  
    return null; // If not found
  };
  
  // Usage
  const plannedDate = extractPlannedDate();
  console.log(plannedDate, "Extracted planned date");
  let formattedDate = plannedDate
  ? new Date(plannedDate).toLocaleDateString("en-GB").replace(/\//g, "-")
  : null;



  useEffect(() => {
    if (plannedDate != null) {
      setPlannedDateTime(plannedDate)
      setConfirmDisabled(plannedDate);
    } else {
      setIsDateTimeInputVisible(!isDateTimeInputVisible);
      setConfirmDisabled(false);
    }
  }, [plannedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInformByEmail = (e) => {
    const checked = e.target.checked;
    setInformByEmail(checked);
    if (checked) {
      setInformByMobile(false); // Uncheck SMS checkbox
    }
  };

  const handleInformByMobile = (e) => {
    const checked = e.target.checked;
    setInformByMobile(checked);
    if (checked) {
      setInformByEmail(false); // Uncheck email checkbox
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  const handleAlert = () => {
    if (informByEmail) {
      setModalMessage("Send Mail?");
    } else if (informByMobile) {
      setModalMessage("Send SMS?");
    } else {
      setModalMessage("No option selected.");
    }
    setIsOpenEmailSMS(true);
  };

  const handleConfirm1 = async (mailId) => {
    //by email
    setIsOpenEmailSMS(false);
    if (informByEmail) {
      console.log(`Sending email to: ${email}`);
      const mailData = {
        to: email, // Replace with actual recipient email
        subject: `Philips TV potwierdzenie serwisu domowego dla  ${detailedData.OrderName} / home service confirmation for ${detailedData.OrderName}`,
        html: `
          <p>Szanowny Kliencie / Dear customer,</p>

          <p>PL</p>
          <p>Numer zlecenia:<strong> ${detailedData.OrderName}</strong></p>
          <p>Zorganizowaliśmy serwis domowy dla Twojego telewizora Philips ${detailedData.ModelName} ,szczegóły poniżej:</p>
          <p>Data serwisu domowego: ${formattedDate}</p>
          <p>Miejsce serwisu domowego: ${detailedData.Street}, ${detailedData.Postcode} ${detailedData.City}, ${detailedData.CountryId}</p>
          <p>Twój numer kontaktowy: ${detailedData.Phone}</p>
          <p>Nasz technik zadzwoni do Ciebie w dniu wizyty około 30 minut przed wizytą.</p>
          <p>Jeśli masz jakieś pytania lub nie będziesz dostępny planowanigo dnia, skontaktuj się z nami za pomocą poniższych kanałów kontaktowych.</p>
          <br/>
	
	        <p>Tel. +48 697 064 771</p>		
	        <p>e-mail: europe.repair@tpv-tech.com</p>				
	        <p>Poniedziałek - piątek 08:00-16:00</p>					
				  <br/>	
	        <p>Ta wiadomość została wygenerowana automatycznie. Prosimy nie odpowiadać na tę wiadomość e-mail.</p>		


          <br/>
          <hr/>
          <br/>

          <p>EN</p>
          <p>Workorder Number:<strong> ${detailedData.OrderName}</strong></p>
          <p>We have arranged a home service for your Philips TV ${detailedData.ModelName} as below:</p>
          <p>Home service date: ${formattedDate}</p>
          <p>Home service location: ${detailedData.Street}, ${detailedData.Postcode} ${detailedData.City}, ${detailedData.CountryId}</p>
          <p>Your contact number: ${detailedData.Phone}</p>
          <p>Our technician will call you on the date around 30 minutes before the visit.</p>
          <p>If you have any questions or you won’t be available at the above time, please contact us by below contact channels.</p>
          <br/>
          <p>Tel. +48 697 064 771</p>
          <p>Email: europe.repair@tpv-tech.com</p>
          <p>Monday - Friday 08:00-16:00</p>
          <br/>
          <p>Please do not reply to this email as it is a no-response email.</p>

          <br/>
          <br/>
          <p>Dziękuję / Thank you.</p>
      
      
          <br/>
          <p>Z wyrazami szacunku / Best regards,</p>
          <p>TPV Displays Sp. z o. o.</p>
          <p>Authorized Servicer of TP Vision for Philips TV</p>
          <p>ul. Złotego Smoka 9, 66-400 Gorzów Wielkopolski, Polska</p>
          <p>NIP: 599-300-52-28</p>
          <br/>
          <p>„W związku z obsługą serwisową Państwa dane osobowe będą przetwarzane zgodnie z klauzulą znajdującą się na stronie https://tpvpolska.pl/rodo/ - Klauzula RODO Naprawa gwarancyjna”</p>
          <br/>
          <p>„In connection with the service, Your personal data will be processed in accordance with the clause found at https://tpvpolska.pl/rodo/ - Klauzula RODO Naprawa gwarancyjna”</p>
        `,
      };

      try {
        await api.post('/sendMail', { mailData });
        // console.log(response.data);
        window.alert("Mail sent sussessfully!.")
      } catch (error) {
        console.error("error sending mail", error);
      }
    }
    //by sms
    if (informByMobile) {
      console.log(`Sending SMS to: ${mobile}`);
      window.alert("SMS sent sussessfully!.")
    }
  };

  const handleCancel = (e) => {
    setIsOpenEmailSMS(false);
  };

  const handleCancel1 = (e) => {
    setIsOpen1(false);
    setToggleNewModelName(false);
  };

  const toggleDateTimeInput = () => {
    setIsDateTimeInputVisible(!isDateTimeInputVisible);
    setConfirmDisabled(false); 
  };

  const handleDateTimeChange = (event) => {
    setPlannedDateTime(event.target.value);
  };

  
  const handleAlert2 = () => {
    if (plannedDateTime) {
     
      setIsOpen1(true)
      console.log(isOpen,isOpen1,"update sattus???")
    } else {
      alert("Planned Date is Empty")
    }
  }

  const handleConfirm2 = async (OrderIssueNr) => {
    console.log("after confirm??");
    setIsOpen1(false);
    setToggleNewModelName(false);

    const updateType = "OrderState";
    try {
        console.log("Calling update API with value 181...");
        const updateResponse1 = await api.post("/updateInfotipaction", {
            OrderIssueNr,
            updatedValue: 181,
            updateType,
            date : 'homevisitplanned date :'+plannedDateTime
        });
        console.log(updateResponse1, "181");

        if (updateResponse1.data.success === true) {
            console.log("Success:", updateResponse1.data);

            console.log("Calling update API with value 351...");
            const updateResponse2 = await api.post("/updateInfotipaction", {
                OrderIssueNr,
                updatedValue: 351,
                updateType
            });
            console.log(updateResponse2, "351");

            if (updateResponse2.data.success === true) {
                console.log("Success:", updateResponse2.data);
                alert("Order updated successfully!");
            } else {
                console.error("Failure:", updateResponse2.data.error);
                alert("Failed to update order. Please try again.");
            }
        } else {
            console.error("Failure:", updateResponse1.data.error);
            alert("Failed to update order. Please try again.");
        }
    } catch (error) {
        console.error("Error updating infotip:", error);
    }
};


const handleRmaClick = () => {
  // Set the detailedData (either fetched or created dynamically)
  const newDetailedData = {
    rmaNumber: detailedData.OrderName,
    date: detailedData.OrderDate,
    tvBrand: 'Philips',
    modelName: detailedData.ProductName,
    serialNumber: detailedData.SerialNumber,
    warrantyStatus: 'Valid',
    purchaseDate: '2023-06-10',
    customerCountry: detailedData.CountryId,
    handlingType: detailedData.HandlingType,
    serviceRepaired: true,
    serviceTakenAway: false,
    serviceDetail: 'Repaired screen issue.'
  };
  setDetailedData(newDetailedData); // Pass the data to state

  // Access the RMA component method via ref and trigger download
  if (rmaRef.current) {
    rmaRef.current.downloadForm(); // This will trigger the form download
  }
};

  return (
    <div style={{ marginLeft: "2%" }}>
      <div className="address">
        <h6>Home Visit address:</h6>
        <div>
          {/* {"1234 Elm Street, City, State, ZIP".split(",").map((val, i) => { */}
          {`${detailedData.Firstline}, ${detailedData.Street}, ${detailedData.Postcode} ${detailedData.City}, ${detailedData.CountryId}`.split(",").map((val, i) => {
            return <p key={i}>{val}</p>;
          })}
        </div>
        <div>
          <p>Tel: {detailedData.Phone}</p>
          <p>Email: {detailedData.Email}</p>
        </div>
        <div>
          {isOpen && (
            <div style={{ marginLeft: "2%" }} className="dropdown-calendar-container">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                inline
                required
                maxDate={currentDate}
                onClickOutside={() => setIsOpen(false)}
              />
            </div>
          )}
        </div>
      </div>

      <div className="plan-request-date-container">
        <h6>Home Visit:</h6>
        <div>

        <div>
            <div style={{ display: 'flex', alignItems: "baseline", gap: '20px' }}>
                <p>Planned Date:</p>
                <input type="date" className="select-styled" value={plannedDateTime} onChange={handleDateTimeChange} disabled={confirmDisabled} />
                <button className="curPo confirm" onClick={handleAlert2} disabled={confirmDisabled}>Confirm (181 + 351)</button>

                <ConfirmationModal
                    isOpen={isOpen1}
                    message="Update Status to Infotip?"
                    onConfirm={() => handleConfirm2(detailedData.OrderIssueNr)}
                    onCancel={handleCancel1}
                />

                <button className="curPo cancel" onClick={handleCancel}>Cancel</button>

              {/* <button
                className="curPo confirm selected-date"
                onClick={toggleDateTimeInput}
              > Change planned date & time</button> */}

            </div>
        </div>

          <div style={{ display: "flex", gap: '20px' }}>
            <span>Inform Customer for home visit details: </span>
            <div>
              {/* <div style={{ display: "grid", gridTemplateColumns: "30px 70px 70px auto" }}> */}
              <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "5px" }}>
                <input className="checkbox-styled" type="checkbox" onChange={handleInformByEmail} checked={informByEmail} />
                <span>by email</span>
                <span>Email:</span>
                <input style={{ boxSizing: "border-box", border: "solid 1px black", flex: "1", minWidth: "200px" }} type="email" id="email" onChange={handleEmailChange} value={email} />
                <button style={{ marginLeft: "10px" }} className="curPo confirm" type="button" onClick={handleAlert}>Confirm</button>
              </div>

              <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "5px" }}>
                <input className="checkbox-styled" type="checkbox" onChange={handleInformByMobile} checked={informByMobile} />
                <span>by SMS</span>
                <span>Mobile:</span>
                <input style={{ boxSizing: "border-box", border: "solid 1px black", flex: "1", minWidth: "200px" }} type="tel" id="myInput" onChange={handleMobileChange} value={mobile} />
                <button style={{ marginLeft: "10px" }} className="curPo confirm" type="button" onClick={handleAlert}>Confirm</button>
              </div>

              {/* <button style={{ margin: '20px' }} className="curPo confirm" type="button" onClick={handleAlert}>Confirm</button> */}
              <ConfirmationModal
                isOpen={isOpenEmailSMS}
                message={modalMessage}
                onConfirm={() => handleConfirm1()}
                onCancel={handleCancel}
              />
            </div>
          </div>

        </div>

      </div> 

      <div>
      {/* Link to trigger PDF generation */}
      <a href="#" onClick={handleRmaClick} style={{ textDecoration: "underline", color: "blue", cursor: "pointer" ,font:"bold"}}>
        RMA Form (RMA-HS)
      </a>

      {/* Hidden RMA Component - Renders in the background */}
      <div style={{ position: "absolute", left: "-9999px", opacity: 0 }}>
        <RMA ref={rmaRef} infotipData={detailedData1} />
      </div>
    </div>
    </div>
  );
}

export default HomeVisit;
