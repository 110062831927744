import axios from "axios";
import { api } from '../axios/AxiosInstances';
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmationModal from './ConfirmationModal';
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function CreateOrder({ onOrderCreated }) {
  const [isOpen, setIsOpen] = useState(false);
  const [toggleCreateOrder, setToggleCreateOrder] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  // const [showAlert, setShowAlert] = useState(false);

  const [wrapups, setWrapups] = useState([]);
  const [wrapup1Options, setWrapup1Options] = useState([]);
  const [wrapup2Options, setWrapup2Options] = useState([]);

  const [modelName, setModelName] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [handlingType, setHandlingType] = useState("");
  const [dateOfPurchase, setDateOfPurchase] = useState(null);
  const [typeOfOrder, setTypeOfOrder] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [wrapUpFirstLevel, setWrapUpFirstLevel] = useState("");
  const [wrapUpSecondLevel, setWrapUpSecondLevel] = useState("");
  const [pickupAddress, setPickupAddress] = useState("");
  const [pickupPhone, setPickupPhone] = useState("");
  const [pickupEmail, setPickupEmail] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryPhone, setDeliveryPhone] = useState("");
  const [deliveryEmail, setDeliveryEmail] = useState("");
  // const [orderDate, setOrderDate] = useState("");
  const currentDate = new Date();

  const dataRef = useRef(null);

  useEffect(() => {
    if (toggleCreateOrder && dataRef.current) {
      dataRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [toggleCreateOrder]);

  useEffect(() => {
    api.get("/wrapups")
      .then((result) => {
        const wrapupSet = [];

        result.data.forEach(obj => {
          const { wrapup_1level, wrapup_2level } = obj;

          if (!wrapupSet[wrapup_1level]) {
            wrapupSet[wrapup_1level] = new Set();;
          }
          wrapupSet[wrapup_1level].add(wrapup_2level);
        });

        for (const key in wrapupSet) {
          if (wrapupSet.hasOwnProperty(key)) {
            wrapupSet[key] = Array.from(wrapupSet[key]);
          }
        }
        // console.log(wrapupSet)
        setWrapups(wrapupSet)
        setWrapup1Options(Object.keys(wrapupSet))
        setWrapup2Options((wrapupSet[Object.keys(wrapupSet)[0]]).sort())
      })
      .catch((err) => {
        console.log("error getting wrapups list", err)
      })
  }, []);

  const handleDateChange = (date) => {
    setDateOfPurchase(date);
    setShowDatePicker(false);
  };

  const toggleDropdown = () => {
    setShowDatePicker(!showDatePicker);
  };

  function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const handleClick = () => {
    setToggleCreateOrder(!toggleCreateOrder);
  };

  const handleCancelOrder = () => {
    setModelName("");
    setSerialNumber("");
    setHandlingType("");
    setDateOfPurchase("");
    setTypeOfOrder("");
    setErrorDescription("");
    setWrapUpFirstLevel("");
    setWrapUpSecondLevel("");
    setPickupAddress("");
    setPickupPhone("");
    setPickupEmail("");
    setDeliveryAddress("");
    setDeliveryPhone("");
    setDeliveryEmail("");
    // setOrderDate("");

    // setShowAlert(false);
  };

  // const handleAlert = () => {
  //   setShowAlert(true);
  // }

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsOpen(true)
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    // setOrderDate(formattedDate);

    const newOrder = {
      Order_Date_Time: formattedDate,
      model_name: modelName,
      Serial_Number: serialNumber,
      Handling_Type: handlingType,
      date_of_purchase: dateOfPurchase,
      Type_of_Order: typeOfOrder,
      error_description: errorDescription,
      wrap_up_first_level: wrapUpFirstLevel,
      wrap_up_second_lev: wrapUpSecondLevel,
      pickup_address: pickupAddress,
      pickup_phone: pickupPhone,
      pickup_email: pickupEmail,
      delivery_address: deliveryAddress,
      delivery_phone: deliveryPhone,
      delivery_email: deliveryEmail,
    };
    // const result = window.confirm("Are you sure ?");

    axios
      .post("http://localhost:5000/createOrder", newOrder)
      .then((result) => {
        console.log(result.data);
        // window.location.reload();
        setIsOpen(false);
        onOrderCreated();
      })
      .catch((error) => {
        console.error("Error Adding order: ", error);
      });
  };

  const handleCancel = (e) => {
    handleCancelOrder();
    setIsOpen(false);
  };

  return (
    <div>
      {/* <div className="create-order">
        <button className="create-button" onClick={handleClick}>
          +
        </button>
        <p>Create New WO</p>
      </div> */}
      {toggleCreateOrder && (
        <form ref={dataRef} onSubmit={handleSubmit}>
          <br></br>
          <ul className="create-order-list">
            <li>
              Model name{" "}
              <input
                value={modelName}
                type="text"
                required
                onChange={(e) => setModelName(e.target.value)}
              ></input>
            </li>
            <li>
              Serial number{" "}
              <input
                value={serialNumber}
                type="text"
                required
                onChange={(e) => setSerialNumber(e.target.value)}
              ></input>
            </li>
            <li>
              Handling type:{" "}
              <select
                value={handlingType}
                onChange={(e) => setHandlingType(e.target.value)}
                required
              >
                <option value="">Select Type</option>
                <option>EXCHANGE</option>
              </select>
            </li>
            <li>
              Date of purchase:{" "}
              <div>
                <div className="selected-date" onClick={toggleDropdown}>
                  {dateOfPurchase ? formatDate(dateOfPurchase) : "Select Date"}
                  {/* <ArrowDropDownIcon onClick={toggleDropdown} /> */}
                </div>
                {showDatePicker && (
                  <div className="dropdown-calendar-container">
                    <DatePicker
                      selected={dateOfPurchase}
                      onChange={handleDateChange}
                      inline
                      required
                      maxDate={currentDate}
                      onClickOutside={() => setShowDatePicker(false)}
                    />
                  </div>
                )}
              </div>
            </li>
            <li>
              Type of order:{" "}
              <select
                value={typeOfOrder}
                onChange={(e) => setTypeOfOrder(e.target.value)}
                required
              >
                <option value="">Select OrderType</option>
                <option>guarantee</option>
                <option>doa</option>
                <option>repair</option>
              </select>
            </li>
            <li>
              Error description:{" "}
              <input
                value={errorDescription}
                type="text"
                required
                onChange={(e) => setErrorDescription(e.target.value)}
              ></input>
            </li>
            <li>
              Wrap up first level:{" "}
              <select
                value={wrapUpFirstLevel}
                onChange={(e) => {
                  setWrapUpFirstLevel(e.target.value);
                  e.target.value !== "" && setWrapup2Options((wrapups[e.target.value]).sort())
                }}
                required
              >
                <option value="">Select First Level</option>
                {/* <option>Picture-related</option>
                <option>Power-related</option>
                <option>Remote control-related</option> */}
                {wrapup1Options.map((data, i) => {
                  return (
                    <option key={i} value={data}>{data}</option>
                  )
                })}
              </select>
            </li>
            <li>
              Wrap-up second level:{" "}
              <select
                value={wrapUpSecondLevel}
                onChange={(e) => setWrapUpSecondLevel(e.target.value)}
                required
              >
                <option value="">Select Second Level</option>
                {/* <option>Dark spots / uneven brightness</option>
                <option>No power</option>
                <option>Low sound / No audio</option> */}
                {wrapup2Options.map((data, i) => {
                  return (
                    <option key={i} value={data}>{data}</option>
                  )
                })}
              </select>
            </li>
            <li>
              Pickup address:{" "}
              <input
                value={pickupAddress}
                type="text"
                required
                onChange={(e) => setPickupAddress(e.target.value)}
              ></input>
            </li>
            <li>
              <span className="contact">Phone #:</span>
              <input
                className="no-spinners"
                value={pickupPhone}
                type="number"
                required
                onChange={(e) => setPickupPhone(e.target.value)}
              ></input>
            </li>
            <li>
              <span className="contact">email:</span>
              <input
                value={pickupEmail}
                type="email"
                required
                onChange={(e) => setPickupEmail(e.target.value)}
              ></input>
            </li>
            <li>
              Delivery address:{" "}
              <input
                value={deliveryAddress}
                type="text"
                required
                onChange={(e) => setDeliveryAddress(e.target.value)}
              ></input>
            </li>
            <li>
              <span className="contact">Phone #:</span>
              <input
                className="no-spinners"
                value={deliveryPhone}
                type="number"
                required
                onChange={(e) => setDeliveryPhone(e.target.value)}
              ></input>
            </li>
            <li>
              <span className="contact">email:</span>
              <input
                value={deliveryEmail}
                type="email"
                required
                onChange={(e) => setDeliveryEmail(e.target.value)}
              ></input>
            </li>
          </ul>
          <div className="create-order-buttons">
            {/* {showAlert ? <div>
              <button className="confirm curPo" type="submit">Confirm</button>
              <button className="curPo" type="reset" onClick={handleCancelOrder}>Cancel</button>
            </div> : <button className="confirm curPo" type="button" onClick={handleAlert}>
              Create WP Workorder
            </button>} */}
            <button className="confirm curPo" type="submit">
              Create WP Workorder
            </button>
            <ConfirmationModal
              isOpen={isOpen}
              message="Are you sure you want to create this order?"
              onConfirm={handleConfirm}
              onCancel={handleCancel}
            />
          </div>
        </form>
      )}
    </div>
  );
}

export default CreateOrder;
