import React, { useContext, useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import TabContext from "../TabContext";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function WoInfotipData({ infotipData, endIndex, loading, currentPage, itemsPerPage }) {
  const [filteredData, setFilteredData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const { openTab } = useContext(TabContext);

  useEffect(() => {
    const workshop = localStorage.getItem('workshop');
    const asp = localStorage.getItem('ASC');
    const usertype = localStorage.getItem('usertype');

    const filtered = infotipData.filter(workOrder => {
      const workshopMatches = !workshop || workOrder.WorkshopName === workshop;
      const aspMatchesTPVTechnician = !workOrder.ASC || workOrder.ASC === '';
      const aspMatchesASCLeader = workOrder.ASC === asp;

      if (usertype === 'TPV Technician') {
        return workshopMatches && aspMatchesTPVTechnician;
      } else if (usertype === 'ASC Leader' || usertype === 'ASC Technician') {
        return aspMatchesASCLeader;
      } else {
        return true;
      }
    });

    setFilteredData(filtered.slice(0, endIndex));
  }, [infotipData, endIndex]);

  const sortData = (key, direction) => {
    setSortConfig({ key, direction });
    const sorted = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setFilteredData(sorted);
  };

  function openTabAndChangeColor(workOrderNumber) {
    document.getElementById(workOrderNumber).style.color = "blue";
    openTab(workOrderNumber);
  }

  const columns = [
    { label: 'Seq#', key: 'seq', disableSort: true  },
    { label: 'WO Date & Time', key: 'OrderDate' },
    { label: 'Infotip WO number', key: 'OrderName' },
    { label: 'Handling Type', key: 'HandlingType' },
    { label: 'Product Name', key: 'ProductName' },
    { label: 'Serial Number', key: 'SerialNumber' },
    { label: 'PostCode Country', key: 'Postcode', disableSort: true },
    { label: 'Workshop', key: 'WorkshopName' },
    { label: 'ASC', key: 'ASC' },
    { label: 'Infotip Status', key: 'OrderState' },
    { label: 'Creator Role', key: 'CreatorRole' },
    { label: 'Type of Order', key: 'TypeOfOrder' },
    { label: 'RR', key: 'RR' },
    { label: 'TAT', key: 'TAT' },
  ];

  return (
    <div className="wo-infotip">
      <h6>WO from Infotip</h6>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", margin: "10px" }}>
          <CircularProgress />
        </div>
      ) : filteredData.length > 0 ? (
        <table>
          <thead>
            <tr className="table-header-row">
              {columns.map((col, idx) => (
                <th key={idx} style={{ cursor: col.disableSort ? 'default' : 'pointer', padding: '8px 10px', textAlign: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span>{col.label}</span>
                    {!col.disableSort && (
                      <div style={{ display: 'flow', marginLeft: '4px' }}>
                        <ArrowDropUpIcon
                          onClick={() => sortData(col.key, 'asc')}
                          style={{ cursor: 'pointer', color: sortConfig.key === col.key && sortConfig.direction === 'asc' ? '#337ab7' : 'grey' }}
                        />
                        <ArrowDropDownIcon
                          onClick={() => sortData(col.key, 'desc')}
                          style={{ cursor: 'pointer', color: sortConfig.key === col.key && sortConfig.direction === 'desc' ? '#337ab7' : 'grey' }}
                        />
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
            <tr>
              <td colSpan="15">
                <hr />
              </td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((workOrder, i) => {
              const pageSeq = (currentPage - 1) * itemsPerPage;
              return (
                <tr className="table-body-row" key={i}>
                  <td>{pageSeq + i + 1}</td>
                  <td>{workOrder.OrderDate}</td>
                  <td className="uline curPo" onClick={() => openTabAndChangeColor(`${workOrder.OrderName}`)} id={`${workOrder.OrderName}`}>{workOrder.OrderName}</td>
                  <td>{workOrder.HandlingType}</td>
                  <td>{workOrder.ProductName}</td>
                  <td>{workOrder.SerialNumber}</td>
                  <td>{workOrder.Postcode} {workOrder.City} {workOrder.CountryId}</td>
                  <td>{workOrder.WorkshopName}</td>
                  <td>{workOrder.ASC}</td>
                  <td className={typeof workOrder.OrderState === 'string' ? (workOrder.OrderState === "96" ? "green-status" : "red-status") : workOrder.OrderState}>{workOrder.OrderState}</td>
                  <td>{workOrder.CreatorRole}</td>
                  <td>{workOrder.TypeOfOrder}</td>
                  <td>{workOrder.RR}</td>
                  <td>{workOrder.TAT}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          No data available
        </div>
      )}
      <hr style={{ color: "rgb(60, 60, 255)" }}></hr>
    </div>
  );
}

export default WoInfotipData;
