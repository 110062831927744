import axios from "axios";
import { api } from '../axios/AxiosInstances';
import React, { useRef, useState, useEffect } from "react";
import { Box, CircularProgress, Backdrop } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmationModal from "./ConfirmationModal";


function PickUp({ detailedData, textOptions, getPythonData }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const currentDate = new Date();
  const fileInputRef = useRef(null);
  const [attachLoading, setAttachLoading] = useState(false)
  const [selectedValues, setSelectedValues] = useState({});
  const [packageCondition, setPackageCondition] = useState("");
  const [packageConditioncomment, setcommentp] = useState("");
  const [comment, setcommenta] = useState("");
  const [accessories, setAccessories] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [ownTransportToggle, setOwnTransportToggle] = useState(false);
  const [reqWHToggle, setReqWHToggle] = useState(false);
  const [plannedDateTime, setPlannedDateTime] = useState("");
  const [reqDateTime, setReqDateTime] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [DateTimearrived, setDateTimearrived] = useState("");
  const [ftpSuccess, setFtpSuccess] = useState(false);
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [isDateTimeInputVisible, setIsDateTimeInputVisible] = useState(false);
  const [confirmDisabled1, setConfirmDisabled1] = useState(true);
  const [confirmDisabled2, setConfirmDisabled2] = useState(true);
  const [confirmDisabled3, setConfirmDisabled3] = useState(false);
  const [confirmDisabled4, setConfirmDisabled4] = useState(false);
  const [selectedAccessories1, setSelectedAccessories1] = useState([]); // State for selected accessories
  const [showCheckbox1, setShowCheckbox1] = useState(false);
  const dropdownRef = useRef(null); // Reference for dropdown
  const [TrackingNr,setTrackingNumber]= useState("");

  useEffect(() => {
    const fetchTrackingNumber = async () => {
      console.log("tn number extract ...??");

      try {
        console.log("calling tn api....");
        const response = await api.post("/tnnumber", {
          OrderIssueNr: detailedData?.OrderIssueNr, // Ensure detailedData exists
        });

        console.log(response, "response from tn api");
        if (response.data.success && response.data.trackingNumbers?.length > 0) {
          // Check if OrderIssueNr is part of any filename
          const matchedRecord = response.data.trackingNumbers.find((item) =>
            item.file.includes(detailedData.OrderIssueNr) // Match filename
          );

          if (matchedRecord) {
            console.log("Match found! Extracting tracking number:", matchedRecord.trackingNumber);
            setTrackingNumber(matchedRecord.trackingNumber); // Save matched tracking number
            handleReqWHToggle()
          } else {
            console.warn("No matching file found for OrderIssueNr.");
          }
        } else {
          console.error("No tracking numbers received or empty response.");
        }

      } catch (error) {
        console.error("Error fetching tracking number infotip:", error);
      }
    };

    if (detailedData?.OrderIssueNr) {
      fetchTrackingNumber(); // Call the async function inside useEffect
    }
  }, [detailedData]); // Add dependencies to prevent unnecessary re-renders

  const extractPlannedDate = () => {
    const entryWithDate = detailedData.history.find(entry =>
      entry.includes("pickupplanned date")
    );

    if (entryWithDate) {
      const match = entryWithDate.match(/pickupplanned date\s*:\s*([\d-]+)/);
      return match ? match[1] : null; // Extract the date part
    }

    return null; // If not found
  };
  // Usage
  const plannedDate = extractPlannedDate();
  console.log(plannedDate, "Extracted planned date");

  useEffect(() => {
    if (plannedDate != null) {
      setPlannedDateTime(plannedDate)
      setConfirmDisabled(plannedDate);
    } else {
      setIsDateTimeInputVisible(!isDateTimeInputVisible);
      setConfirmDisabled(false);
    }
  }, [plannedDate]);

  const toggleDateTimeInput = () => {
    setIsDateTimeInputVisible(!isDateTimeInputVisible);
    setConfirmDisabled(false);
  };

  //pickup req date
  const extractPickupReqDate = () => {
    const entryWithDate = detailedData.history.find(entry =>
      entry.includes("pickuprequest date")
    );

    if (entryWithDate) {
      const match = entryWithDate.match(/pickuprequest date\s*:\s*([\d-]+)/);
      return match ? match[1] : null; // Extract the date part
    }

    return null; // If not found
  };
  // Usage
  const PickupReqDate = extractPickupReqDate();
  console.log(PickupReqDate, "Extracted req date");

  useEffect(() => {
    if (PickupReqDate != null) {
      setReqDateTime(PickupReqDate)
      setConfirmDisabled1(PickupReqDate);
    } else {
      setIsDateTimeInputVisible(!isDateTimeInputVisible);
      setConfirmDisabled1(false);
    }
  }, [PickupReqDate]);


  //pickup req date
  const extractDefDate = () => {
    const entryWithDate = detailedData.history.find(entry =>
      entry.includes("defecttvarrived date")
    );

    if (entryWithDate) {
      const match = entryWithDate.match(/defecttvarrived date\s*:\s*([\d-]+)/);
      return match ? match[1] : null; // Extract the date part
    }

    return null; // If not found
  };
  // Usage
  const DefDate = extractDefDate();
  console.log(DefDate, "Extracted def date");

  useEffect(() => {
    if (DefDate != null) {
      setDateTimearrived(DefDate)
      setConfirmDisabled2(DefDate);
    } else {
      setIsDateTimeInputVisible(!isDateTimeInputVisible);
      setConfirmDisabled2(false);
    }
  }, [DefDate]);

  const extractpkgconditionAndComment = () => {
    const entryWithAccessories = detailedData.history.find(entry =>
      entry.includes("packageCondition")
    );

    if (entryWithAccessories) {
      const accessoriesMatch = entryWithAccessories.match(/packageCondition\s*:\s*(\w+)/);
      const commentMatch = entryWithAccessories.match(/comment\s*:\s*([^|]+)/);

      return {
        packageCondition: accessoriesMatch ? accessoriesMatch[1].trim() : null,
        comment: commentMatch ? commentMatch[1].trim() : null
      };
    }

    return { packageCondition: null, comment: null };
  };

  useEffect(() => {
    const { packageCondition, comment } = extractpkgconditionAndComment();

    if (packageCondition !== null) {
      setPackageCondition(packageCondition)
      // setSelectedAccessories1(accessories)
      setcommentp(comment); // Set comment based on accessories
      setConfirmDisabled3(true)
    }
  }, [detailedData.history]); // Re-run effect when history changes

  console.log(packageCondition, "Extracted pkg");
  console.log(comment, "Extracted comment");


  const extractAccessoriesAndComment = () => {
    const entryWithAccessories = detailedData.history.find(entry =>
      entry.includes("accessories")
    );

    if (entryWithAccessories) {
      // const accessoriesMatch = entryWithAccessories.match(/accessories\s*:\s*([^,]+(?:,\s*[^:]+)*)/);
      const accessoriesMatch = entryWithAccessories.match(/accessories\s*:\s*([^,]+(?:,\s*[^:,]+)*)(?=\s*,?\s*comment|$)/);
      const commentMatch = entryWithAccessories.match(/comment\s*:\s*([^|]+)/);

      return {
        accessories: accessoriesMatch ? accessoriesMatch[1].trim() : null,
        comment: commentMatch ? commentMatch[1].trim() : null
      };
    }

    return { accessories: null, comment: null };
  };

  useEffect(() => {
    const { accessories, comment } = extractAccessoriesAndComment();

    if (accessories !== null) {
      const accessoriesArray = accessories.split(",").map(item => item.trim()); // Convert to array
      setAccessories(accessories);
      setSelectedAccessories1(accessoriesArray); // Set as an array
      setcommenta(comment); // Set comment based on accessories
      setConfirmDisabled4(true)
    }
  }, [detailedData.history]); // Re-run effect when history changes

  console.log(accessories, selectedAccessories1, "Extracted accessories");
  console.log(comment, "Extracted comment");

  const handleOwnTransport = () => {
    setOwnTransportToggle(true);
  };

  const handleOwnTransportCancel = () => {
    setOwnTransportToggle(false);
  };

  const handleReqWHToggle = () => {
    setReqWHToggle(true);
  };

  const handleReqWHToggleCancel = () => {
    setReqWHToggle(false);
  };


  const handleDateChange = (date) => {
    setSelectedDate(date);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };



  const handlePackageConditionChange = (event) => {
    setPackageCondition(event.target.value);
  };

  const handleAccessoryChange = (event) => {
    const newAccessories = [...accessories];
    if (event.target.checked) {
      newAccessories.push(event.target.value);
    } else {
      const index = newAccessories.indexOf(event.target.value);
      newAccessories.splice(index, 1);
    }
    setAccessories(newAccessories);
    // console.log(accessories)
  };

  var expanded = false;

  const showCheckboxes = () => {
    var checkboxes = document.getElementById("checkboxes");
    if (!expanded) {
      checkboxes.style.display = "block";
      expanded = true;
    } else {
      checkboxes.style.display = "none";
      expanded = false;
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handlePhotoUpload = (event) => {
    const fileTypes = [".jpg", ".jpeg", ".gif", ".pdf", ".png"]
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileSize = file.size / (1024 * 1024)
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!fileTypes.includes("." + fileExtension)) {
        window.alert(`${fileExtension} file is not allowed!`)
        event.target.value = '';
        return
      }

      if (fileSize > 3) {
        window.alert("File is larger than 3MB")
        event.target.value = '';
        return;
      }
      const newPhotos = [...photos];

      const reader = new FileReader(); // FileReader is instantiated.
      reader.onloadend = () => { // onloadend handler is defined but not yet executed.
        const base64String = reader.result.split(',')[1];
        newPhotos.push({
          fileName: file.name,
          // filePath: file.path,
          dateTime: new Date().toLocaleString(),
          fileExt: fileExtension,
          content: base64String,
        });
        setPhotos(newPhotos);

        event.target.value = '';
      };
      reader.readAsDataURL(file); // readAsDataURL method starts reading the file.
    } else {
      console.log("No file selected");
    }
  };

  const handleCancel = (delFile) => {
    const newPhotos = photos.filter(photo => photo.fileName !== delFile);
    setPhotos(newPhotos);
  }

  // function getRandomUniqueNumber(min, max, existingNumbers) {
  //   let randomNumber;
  //   do {
  //     randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  //   } while (existingNumbers.includes(randomNumber));
  //   return randomNumber..toString();
  // }

  function getUniqueIndex(arr) {
    const numberArray = arr.map(str => parseInt(str, 10));
    // const numberArray = arr.map(Number);
    const maxNumber = Math.max(...numberArray);
    return (maxNumber + 1).toString();
  }

  const handleConfirm = async (fileName, fileExtension, content, textValue) => {
    // console.log(filePath)
    setAttachLoading(true)
    try {
      const orderIssueNr = detailedData.OrderIssueNr
      const orderName = detailedData.OrderName
      const uniqueIndex = (getUniqueIndex(detailedData.docIndexes))
      // const uniqueIndex = getRandomUniqueNumber(500, 899, detailedData.docIndexes);
      const docName = `${orderName}-${uniqueIndex}.${fileExtension}`;

      const response = await api.post("/sendAttachment", { orderIssueNr, uniqueIndex, docName, content, textValue })
      // console.log(response)
      if (response.data.split('|')[0].trim() === "0") {
        setAttachLoading(false)
        window.alert("Attachment sent to Infotip")
        handleCancel(fileName)
        getPythonData(detailedData.OrderName)
      } else {
        setAttachLoading(false)
        window.alert("Error sending the attachment1")
      }
    } catch (error) {
      setAttachLoading(false)
      window.alert("Error sending the attachment")
      console.log("error sending the attachment")
    }
  }

  const handleSelectChange = (i, value) => {
    setSelectedValues(prevState => ({
      ...prevState,
      [i]: value
    }));
  };


  const handleDateTimeChange = (event) => {
    setPlannedDateTime(event.target.value);
  };

  const handlereqDateTimeChange = (event) => {
    setReqDateTime(event.target.value);
  };

  const handleDateTimearrived = (event) => {
    setDateTimearrived(event.target.value);
  };

  const handleConfirm1 = async (OrderIssueNr) => {
    console.log("after confirm??")
    setIsOpen1(false);
    // setToggleNewModelName(false);

    const updateType = "OrderState";
    const updatedValue = 351;

    // Fetch updated data
    try {
      console.log("calling update api....")
      const updateResponse = await api.post("/updateInfotipaction", {
        OrderIssueNr: OrderIssueNr, // Use the function parameter directly
        updatedValue: updatedValue,
        updateType: updateType,
        date: 'pickupplanned date :' + plannedDateTime
      });

      // const updateStatus = updateResponse.data.split('|')[2].trim();
      console.log(updateResponse, "response from status update api");
      if (updateResponse.data.success == true) {
        console.log("Success:", updateResponse.data);
        alert("Order updated successfully!");
      } else {
        console.error("Failure:", updateResponse.data.error);
        alert("Failed to update order. Please try again.");
      }

    } catch (error) {
      console.error("Error updating infotip:", error);
      alert("Failed to update order. Please try again.");
    }
  };

  const handleAlert1 = () => {
    if (plannedDateTime) {

      setIsOpen1(true)
      console.log(isOpen, isOpen1, "update sattus???")
    } else {
      alert("Planned Date is Empty")
    }
  }

  const handleCancel1 = (e) => {
    setIsOpen1(false);
    // setToggleNewModelName(false);
  };

  const handleConfirm2 = async (OrderIssueNr) => {
    console.log("after confirm??")
    setIsOpen2(false);
    // setToggleNewModelName(false);

    const updateType = "OrderState";
    const updatedValue = 351;

    // Fetch updated data
    // try {
    //   console.log("calling update api....")
    //   const updateResponse = await api.post("/updateInfotipaction", {
    //     OrderIssueNr: OrderIssueNr, // Use the function parameter directly
    //     updatedValue: updatedValue,
    //     updateType: updateType
    //   });

    //   // const updateStatus = updateResponse.data.split('|')[2].trim();
    //   console.log(updateResponse, "response from status update api");
    //   if (updateResponse.data.success == true) {
    //     console.log("Success:", updateResponse.data);
    //     alert("Order updated successfully!");
    //   } else {
    //     console.error("Failure:", updateResponse.data.error);
    //     alert("Failed to update order. Please try again.");
    //   }

    // } catch (error) {
    //   console.error("Error updating infotip:", error);
    //   alert("Failed to update order. Please try again.");
    // }

    try {
      console.log("Calling FTP update API....");
      detailedData.SalesDate = reqDateTime
      const ftpResponse = await api.post("/updateftp", {
        updatedata: detailedData
      });

      console.log(ftpResponse, "Response from FTP update API");

      if (ftpResponse.data.success === true) {
        console.log("FTP Update Successful. Now calling update API...");

        // Call updateInfotipaction API after successful FTP update
        const updateResponse = await api.post("/updateInfotipaction", {
          OrderIssueNr: OrderIssueNr,
          updatedValue: updatedValue,
          updateType: updateType,
          date: 'pickuprequest date :' + reqDateTime
        });

        console.log(updateResponse, "Response from status update API");

        if (updateResponse.data.success === true) {
          alert("Order and FTP updated successfully!");
          //set pickup flag
          setFtpSuccess(true)
        } else {
          console.error("Update API Failure:", updateResponse.data.error);
          alert("FTP update successful, but order update failed.");
        }
      } else {
        console.error("FTP Update Failure:", ftpResponse.data.error);
        alert("FTP update failed. Please try again.");
      }
    } catch (error) {
      console.error("Error updating:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleAlert2 = () => {
    if (reqDateTime) {

      setIsOpen2(true)
      console.log(isOpen, isOpen1, "update sattus???")
    } else {
      alert("Request WH Date is Empty")
    }
  }

  const handleCancel2 = (e) => {
    setIsOpen2(false);
    // setToggleNewModelName(false);
  };

  const handleConfirm3 = async (OrderIssueNr) => {
    console.log("after confirm??")
    setIsOpen3(false);
    // setToggleNewModelName(false);

    const updateType = "OrderState";
    const updatedValue = 130;

    // Fetch updated data
    try {
      console.log("calling update api....")
      const updateResponse = await api.post("/updateInfotipaction", {
        OrderIssueNr: OrderIssueNr, // Use the function parameter directly
        updatedValue: updatedValue,
        updateType: updateType,
        date: 'defecttvarrived date :' + DateTimearrived
      });

      // const updateStatus = updateResponse.data.split('|')[2].trim();
      console.log(updateResponse, "response from status update api");
      if (updateResponse.data.success == true) {
        console.log("Success:", updateResponse.data);
        alert("Order updated successfully!");
        // onSuccess();
      } else {
        console.error("Failure:", updateResponse.data.error);
        alert("Failed to update order. Please try again.");
      }

    } catch (error) {
      console.error("Error updating infotip:", error);
      alert("Failed to update order. Please try again.");
    }
  };

  const handleAlert3 = () => {
    if (DateTimearrived) {

      setIsOpen3(true)
      console.log(isOpen, isOpen1, "update sattus???")
    } else {
      alert("Date of Defective is Empty")
    }
  }

  const handleCancel3 = (e) => {
    setIsOpen3(false);
    // setToggleNewModelName(false);
  };

  //package condition
  const handleAlert4 = () => {
    if (packageCondition) {
      setIsOpen4(true)
      console.log(isOpen, isOpen1, "update sattus???")
    } else {
      alert("Package condition is Empty")
    }
  }


  const handleConfirm4 = async (OrderIssueNr) => {
    console.log("after confirm44444444??")
    setIsOpen4(false);
    // setcomment(packageCondition)
    const updateType = "OrderState";
    const updatedValue = 102;
    let data;
    if (packageConditioncomment) {
      data = 'packageCondition :' + packageCondition + ', comment :' + packageConditioncomment
    } else {
      data = 'packageCondition :' + packageCondition
    }


    // Fetch updated data
    try {
      console.log("calling update api....")
      const updateResponse = await api.post("/updateInfotipaction", {
        OrderIssueNr: OrderIssueNr, // Use the function parameter directly
        updatedValue: updatedValue,
        updateType: updateType,
        date: data
      });

      // const updateStatus = updateResponse.data.split('|')[2].trim();
      console.log(updateResponse, "response from status update api");
      if (updateResponse.data.success == true) {
        console.log("Success:", updateResponse.data);
        alert("Order updated successfully!");
        // onSuccess();
      } else {
        console.error("Failure:", updateResponse.data.error);
        alert("Failed to update order. Please try again.");
      }

    } catch (error) {
      console.error("Error updating infotip:", error);
      alert("Failed to update order. Please try again.");
    }
  };


  const handleCancel4 = (e) => {
    setIsOpen4(false);
    setPackageCondition("")
    setcommentp("")
  };

  const handleConfirm5 = async (OrderIssueNr) => {
    console.log("after confirm55??")
    setIsOpen5(false);
    const selectedText = selectedAccessories1.join(", "); // Convert to comma-separated string
    // setComment(selectedText);
    const updateType = "OrderState";
    const updatedValue = 102;
    let data;
    if (comment) {
      data = 'accessories :' + selectedText + ', comment :' + comment
    } else {
      data = 'accessories :' + selectedText
    }
    // Fetch updated data
    try {
      console.log("calling update api....")
      const updateResponse = await api.post("/updateInfotipaction", {
        OrderIssueNr: OrderIssueNr, // Use the function parameter directly
        updatedValue: updatedValue,
        updateType: updateType,
        date: data,
      });

      // const updateStatus = updateResponse.data.split('|')[2].trim();
      console.log(updateResponse, "response from status update api");
      if (updateResponse.data.success == true) {
        console.log("Success:", updateResponse.data);
        alert("Order updated successfully!");
      } else {
        console.error("Failure:", updateResponse.data.error);
        alert("Failed to update order. Please try again.");
      }

    } catch (error) {
      console.error("Error updating infotip:", error);
      alert("Failed to update order. Please try again.");
    }
  };



  const handleCancel5 = (e) => {
    setIsOpen5(false);
    setcommenta(""); // Clear comment box
    setSelectedAccessories1([]); // Clear selected checkboxes
    document.querySelectorAll("#checkboxes input[type='checkbox']").forEach((checkbox) => {
      checkbox.checked = false;
    });
  };
  const handleAlert5 = () => {
    if (selectedAccessories1.length > 0) {
      setIsOpen5(true)
      console.log(isOpen, isOpen1, "update sattus???")
    } else {
      alert("Accessories is Empty")
    }
  }

  // Handles checkbox selection
  const handleAccessoryChange1 = (event) => {
    const { value, checked } = event.target;

    setSelectedAccessories1((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  // Toggle checkbox dropdown visibility
  const toggleCheckboxes = () => {
    setShowCheckbox1(!showCheckbox1);
    console.log(showCheckbox1)
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCheckbox1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{ marginLeft: "2%" }}>
      <div className="address">
        <h6>Pickup address:</h6>
        <div>
          {/* {"1234 Elm Street, City, State, ZIP".split(",").map((val, i) => { */}
          {`${detailedData.Firstline}, ${detailedData.Street}, ${detailedData.Postcode} ${detailedData.City}, ${detailedData.CountryId}`.split(",").map((val, i) => {
            return <p key={i}>{val}</p>;
          })}
        </div>
        <div>
          <p>Tel: {detailedData.Phone}</p>
          <p>Email: {detailedData.Email}</p>
        </div>
        <div>
          {isOpen && (
            <div className="dropdown-calendar-container">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                inline
                required
                maxDate={currentDate}
                onClickOutside={() => setIsOpen(false)}
              />
            </div>
          )}
        </div>
      </div>

      <div className="plan-request-date-container">
        <h6>Pickup:</h6>
        <div className="plan-request-date" style={{ display: "flex", gap: "20px" }}>
          {/* Own Transport Section */}
          <div className="plan-date" style={{ display: "flex", alignItems: "baseline", gap: "10px" }}>
            <button className="curPo confirm" onClick={handleOwnTransport}>
              Own Transport
            </button>

            {ownTransportToggle && (
              <>
                <p>Planned Date :</p>
                <input type="date" className="select-styled" value={plannedDateTime} disabled={confirmDisabled} onChange={handleDateTimeChange} />
                <button className="curPo confirm" onClick={handleAlert1} disabled={confirmDisabled}>Confirm (351)</button>

                <ConfirmationModal
                  isOpen={isOpen1}
                  message="Update Status to Infotip?"
                  onConfirm={() => handleConfirm1(detailedData.OrderIssueNr)}
                  onCancel={handleCancel1}
                />

                <button className="curPo cancel" onClick={handleOwnTransportCancel}>
                  Cancel
                </button>
                {/* <button className="curPo confirm selected-date" onClick={toggleDateTimeInput}>
                  Change planned date & time
                </button> */}
              </>
            )}
          </div>

          {/* Request WH Pickup Section */}
          <div className="request-wh" style={{ display: "flex", alignItems: "baseline", gap: "10px" }}>
            <button className="curPo confirm" onClick={handleReqWHToggle}>
              Request WH Pickup
            </button>

            {reqWHToggle && (
              <>
                <p>Request Date & Time:</p>
                <input type="date" className="select-styled" value={reqDateTime} disabled={confirmDisabled1} onChange={handlereqDateTimeChange} />
                <button className="curPo confirm" onClick={handleAlert2} disabled={confirmDisabled1}>Confirm (351)</button>

                <ConfirmationModal
                  isOpen={isOpen2}
                  message="Update Status to Infotip?"
                  onConfirm={() => handleConfirm2(detailedData.OrderIssueNr)}
                  onCancel={handleCancel2}
                />
                <button className="curPo cancel" onClick={handleReqWHToggleCancel}>
                  Cancel
                </button>
                {/* Display tracking info only if TrackingNr exists */}
                {TrackingNr && (
                  <>
                    <span style={{ border: "1px solid black", padding: "5px" }}>Pickup requested</span>
                    <span>Pickup TN: </span><span style={{ border: "1px solid black", padding: "5px" }}>{TrackingNr}</span>
                    <span>Pickup Status: </span><span style={{ border: "1px solid black", padding: "5px" }}>In transport</span>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <br></br>

      <div >
        <div style={{ display: "flex", alignItems: "baseline", gap: "10px" }}>
          <h6>Date of Defective TV arrived: </h6>

          <input type="date" className="select-styled" value={DateTimearrived} disabled={confirmDisabled2} onChange={handleDateTimearrived} />
          <button className="curPo confirm" onClick={handleAlert3} disabled={confirmDisabled2}>Confirm (130)</button>

          <ConfirmationModal
            isOpen={isOpen3}
            message="Update Status to Infotip?"
            onConfirm={() => handleConfirm3(detailedData.OrderIssueNr)}
            onCancel={handleCancel3}
          />

          {/* <button className="curPo cancel" onClick={handleOwnTransportCancel}>
            Cancel
          </button> */}
        </div>
        <br></br>

        <div style={{ gap: "50px" }}>
          <h6>Condition of Defective TV packaging</h6>
          <div style={{ display: "flex", flexDirection: "column", gap: "15px", marginBottom: "10px", gridTemplateColumns: '200px,200px,auto' }}>

            {/* First Row */}
            <div style={{ display: "flex", alignItems: "center", gap: "20px", flexWrap: "wrap" }}>
              <label htmlFor="packageCondition">Package condition:</label>
              <select
                className="select-styled"
                id="packageCondition"
                value={packageCondition}
                onChange={handlePackageConditionChange}
                style={{ flexShrink: 0, width: "210px" }}
              >
                <option value="">Select condition</option>
                <option value="good">Original packaging: good</option>
                <option value="damaged">Original packaging: damaged</option>
                <option value="good">Other packaging: good</option>
                <option value="damaged">Other packaging: damaged</option>
              </select>

              <label htmlFor="comment">Comment:</label>
              <input className="input-styled" style={{ width: "250px", flexShrink: 0 }} id="comment" value={packageConditioncomment}
                onChange={(e) => setcommentp(e.target.value)} />

              <button className="curPo confirm" onClick={() => handleAlert4(detailedData.OrderIssueNr)} disabled={confirmDisabled3}>
                Confirm
              </button>


              <ConfirmationModal
                isOpen={isOpen4}
                message="Update Status to Infotip?"
                onConfirm={() => handleConfirm4(detailedData.OrderIssueNr)}
                onCancel={handleCancel4}
              />

              <button className="curPo cancel" onClick={handleCancel4}>
                Cancel
              </button>
            </div>

            {/* Accessories Row */}
            <div style={{ display: "flex", alignItems: "center", gap: "20px", flexWrap: "wrap" }}>
              <label>Accessories:</label>

              {/* Custom Select Box */}
              <div ref={dropdownRef} style={{ position: "relative", width: "250px" }}>
                <div
                  className="selectBox"
                  onClick={toggleCheckboxes}
                  style={{
                    border: "1px solid #ccc",
                    padding: "5px",
                    cursor: "pointer",
                    background: "rgb(214, 228, 235)",
                  }}
                >
                  {selectedAccessories1.length > 0 ? selectedAccessories1.join(", ") : "Select Accessories"}
                </div>
                {/* Checkbox List */}
                {/* {showCheckbox1 && (
                  <div id="checkboxes1" style={{ position: "absolute", top: "30px", left: "0", background: "white", border: "1px solid #ccc", padding: "10px" }}>
                    <label><input type="checkbox" value="Remote Control" onChange={handleAccessoryChange1} /> Remote Control</label><br />
                    <label><input type="checkbox" value="Stand with screws" onChange={handleAccessoryChange1} /> Stand with screws</label><br />
                    <label><input type="checkbox" value="Power cable" onChange={handleAccessoryChange1} /> Power cable</label><br />
                    <label><input type="checkbox" value="Others" onChange={handleAccessoryChange1} /> Others</label><br />
                    <label><input type="checkbox" value="None" onChange={handleAccessoryChange1} /> None</label>
                  </div>
                )} */}
                {/* Checkbox List */}
                {showCheckbox1 && (
                  <div id="checkboxes1" style={{ position: "absolute", top: "30px", left: "0", background: "white", border: "1px solid #ccc", padding: "10px" }}>
                    {["Remote Control", "Stand with screws", "Power cable", "Others", "None"].map((item) => (
                      <div key={item} style={{ marginBottom: "5px" }}> {/* Wrap in div for proper line break */}
                        <label>
                          <input
                            type="checkbox"
                            value={item}
                            checked={selectedAccessories1.includes(item)}
                            onChange={handleAccessoryChange1}
                          /> {item}
                        </label>
                      </div>
                    ))}
                  </div>
                )}

              </div>

              {/* Input Field Showing Selected Accessories */}
              <label htmlFor="accessoryComment">Comment:</label>
              <input
                className="input-styled"
                style={{ width: "250px" }}
                id="comment"
                value={comment}
                onChange={(e) => setcommenta(e.target.value)}
              />
              <button className="curPo confirm" onClick={() => handleAlert5(detailedData.OrderIssueNr)}disabled={confirmDisabled4}>
                Confirm
              </button>

              <ConfirmationModal
                isOpen={isOpen5}
                message="Update Status to Infotip?"
                onConfirm={() => handleConfirm5(detailedData.OrderIssueNr)}
                onCancel={handleCancel5}
              />

              {/* Cancel Button */}
              <button className="curPo cancel" onClick={() => {
                console.log("Cancelled");
                handleCancel5();
              }}>
                Cancel
              </button>

            </div>
          </div>
        </div>

        {/* <div>
          <label htmlFor="photoUpload">Add photo:</label>
          <input
            type="file"
            id="photoUpload"
            accept=".jpg, .jpeg, .gif, .pdf, .png"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handlePhotoUpload}
          />
          <button className="curPo confirm" onClick={handleButtonClick}>Choose File</button>
        </div> */}
        {/* {photos.length !== 0 && (
          <div style={{ display: "flex" }}>
            <h6>Photos</h6>
            <table className="photos-table" style={{ borderSpacing: 20 }}>
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>Date Time</th>
                  <th>Assign Infotip Attachment Type</th>
                  <th>Send to Infotip</th>
                </tr>
              </thead>
              <tbody>
                {photos.map((photo, i) => {
                  const textValue = selectedValues[i] || '';
                  return (
                    <tr key={i}>
                      <td>{photo.fileName}</td>
                      <td>{photo.dateTime}</td>
                      <td>
                        <select className="select-styled" style={{ marginLeft: "-5px" }} required value={textValue} onChange={(e) => handleSelectChange(i, e.target.value)}>
                          <option value="">Select</option>
                          {textOptions && Object.keys(textOptions).length > 0 && Object.entries(textOptions).map(([key, value]) => (
                            <option key={key} value={value}>{key}</option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <button className="confirm curPo" type="button" onClick={() => { handleConfirm(photo.fileName, photo.fileExt, photo.content, textValue) }}>Confirm</button>
                        <button className="curPo cancel" onClick={() => handleCancel(photo.fileName)} type="button">Cancel</button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )} */}
      </div>

      {/* {attachLoading &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <Box
            sx={{
              display: 'flex',
              height: '70vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress sx={{ color: 'grey.500' }} />
          </Box>
        </Backdrop>} */}
    </div>
  );
}

export default PickUp;
