import React, { useState ,useEffect} from "react";
import HomeVisit from "./HomeVisit";
import PickUp from "./PickUp";

function HomePick({ detailedData, textOptions, getPythonData, setLoading }) {
  const [toggleHomeVisit, setToggleHomeVisit] = useState(false);
  const [togglePickUp, setTogglePickUp] = useState(false);

  const handleClickAssign = () => {
    setToggleHomeVisit(!toggleHomeVisit);
  };

  const handleClickOrder = () => {
    setTogglePickUp(!togglePickUp);
  };


  //Homevisit...
  const extractPlannedDate = () => {
    const entryWithDate = detailedData.history.find(entry =>
      entry.includes("homevisitplanned date")
    );

    if (entryWithDate) {
      const match = entryWithDate.match(/homevisitplanned date\s*:\s*([\d-]+)/);
      return match ? match[1] : null; // Extract the date part
    }

    return null; // If not found
  };

  // Usage
  const plannedDate = extractPlannedDate();

  useEffect(() => {
    if (plannedDate != null) {
      setToggleHomeVisit(true);
    }
  }, [plannedDate]);


  //PIckup.....
  const extractpkgconditionAndComment = () => {
    const entryWithAccessories = detailedData.history.find(entry =>
      entry.includes("packageCondition")
    );

    if (entryWithAccessories) {
      const accessoriesMatch = entryWithAccessories.match(/packageCondition\s*:\s*(\w+)/);
      const commentMatch = entryWithAccessories.match(/comment\s*:\s*([^|]+)/);

      return {
        packageCondition: accessoriesMatch ? accessoriesMatch[1].trim() : null,
        comment: commentMatch ? commentMatch[1].trim() : null
      };
    }

    return { packageCondition: null, comment: null };
  };

  useEffect(() => {
    const { packageCondition, comment } = extractpkgconditionAndComment();
    if (packageCondition !== null) {
      setTogglePickUp(true);
    }
  }, [detailedData.history]);


  const extractAccessoriesAndComment = () => {
    const entryWithAccessories = detailedData.history.find(entry =>
      entry.includes("accessories")
    );

    if (entryWithAccessories) {
      // const accessoriesMatch = entryWithAccessories.match(/accessories\s*:\s*([^,]+(?:,\s*[^:]+)*)/);
      const accessoriesMatch = entryWithAccessories.match(/accessories\s*:\s*([^,]+(?:,\s*[^:,]+)*)(?=\s*,?\s*comment|$)/);
      const commentMatch = entryWithAccessories.match(/comment\s*:\s*([^|]+)/);

      return {
        accessories: accessoriesMatch ? accessoriesMatch[1].trim() : null,
        comment: commentMatch ? commentMatch[1].trim() : null
      };
    }

    return { accessories: null, comment: null };
  };

  useEffect(() => {
    const { accessories, comment } = extractAccessoriesAndComment();

    if (accessories !== null) {
      setTogglePickUp(true);
    }
  }, [detailedData.history]); // Re-run effect when history changes

  //pickup req date
  const extractPickupReqDate = () => {
    const entryWithDate = detailedData.history.find(entry =>
      entry.includes("pickuprequest date")
    );

    if (entryWithDate) {
      const match = entryWithDate.match(/pickuprequest date\s*:\s*([\d-]+)/);
      return match ? match[1] : null; // Extract the date part
    }

    return null; // If not found
  };
  // Usage
  const PickupReqDate = extractPickupReqDate();

  useEffect(() => {
    if (PickupReqDate != null) {
      setTogglePickUp(true);
    }
  }, [PickupReqDate]);


  //pickup req date
  const extractDefDate = () => {
    const entryWithDate = detailedData.history.find(entry =>
      entry.includes("defecttvarrived date")
    );

    if (entryWithDate) {
      const match = entryWithDate.match(/defecttvarrived date\s*:\s*([\d-]+)/);
      return match ? match[1] : null; // Extract the date part
    }

    return null; // If not found
  };
  // Usage
  const DefDate = extractDefDate();

  useEffect(() => {
    if (DefDate != null) {
      setTogglePickUp(true);
    }
  }, [DefDate]);

  

  return (

    <div>
      <div className="toggle-box">
        <button onClick={handleClickAssign}>-</button>
        <h6>Home Repair Visit Schedule Arrangement</h6>
      </div>
      {toggleHomeVisit && <HomeVisit detailedData={detailedData} />}
      <div className="toggle-box">
        <button onClick={handleClickOrder}>-</button>
        <h6>Pick Up</h6>
      </div>
      {togglePickUp && <PickUp detailedData={detailedData} textOptions={textOptions} getPythonData={getPythonData} setLoading={setLoading}/>}
    </div>
  );
}

export default HomePick;
